import React from "react";
import { connect, } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { Link } from "react-router-dom";
import * as actions from "../../redux/actions/actions";
import AuthService from "../../../Config/msalConfig";
import Bandwidth from "./banwidthNumber";
import Logo from "../logo";
import Login from "../../Login/login";
import { store } from "../../..";

class RegisterTerms extends React.Component<any, any> {
	state = {
		terms: false,
		policy: false,
		accessToken: "",
		loginPage: false,
		clickNumber:0,
		disabled:true,
		ridirectHome:false,
		registerTheme:"",
	}
	redirectToHome = () => {
		const { history } = this.props;
		if (history) {
			history.push('/')
		}
	}
	redirectToBandwidth = () => {
		const { history } = this.props;
		if (history) {
			history.push('/nobandwidthnumber') 
	   }
		setTimeout(() => {
			if (history) {
				history.push('/nobandwidthnumber') 
		   }	
		}, 500);
		
	}
	handelCancel = () => {
		var company = this.props.companyDetails_registeration.company;
		var subscription = this.props.subscriptionDetails_registeration;
		var user = this.props.userDetails_registeration
		this.props.cancelBtnMailChimp(company, subscription, user, this.state.accessToken);
		this.props.cancelBtnZoho(company, subscription, user, this.state.accessToken);
	}
	handelSaveRegistration = () => {
		var company = this.props.companyDetails_registeration?.company;
		var subscription = this.props.subscriptionDetails_registeration;
		var user = this.props.userDetails_registeration;	
        if(this.state.clickNumber === 0 && this.state.disabled === true){
			if (this.state.terms === true && this.state.policy === true) {
				this.props.registerCompanyTrail(company, subscription, user, this.state.accessToken);
				this.setState({clickNumber:1,disabled:false});		
			}	}
		if (this.props?.isNumber === true && subscription.Subscription === 6) {
			setTimeout(() => {
				this.setState({ ridirectHome: true });
				this.setState({ loginPage: false });
				this.redirectToBandwidth()
			}, 1800);
		}else if(this.props.isNumber !== true  && this.state.ridirectHome === false){
			setTimeout(() => {
				if(this.props.isNumber !== true  && this.state.ridirectHome === false){
				this.setState({ loginPage: true });
				this.redirectToHome()
				AuthService.initSignin()
				window.location.reload();
				}				
			}, 2100);
		}
		
	}
	static getDerivedStateFromProps(nextProps: any, prevState: any) {
		if (nextProps.accessToken !== prevState.accessTokenApi  ||
			nextProps.registerTheme !== prevState.registerTheme)
			return {
				accessToken: nextProps.accessToken,
				registerTheme: nextProps.registerTheme,
			}
		else return null;
	}
	render() {
		const { history } = this.props;
		return (
			<>
				{this.props.isNumber ? <Bandwidth /> :
					this.state.loginPage ? <Login loginStatus={this.props.loggedInUser}></Login> :
						<div className={this.state.registerTheme === "calltower" ? "userlog_sec calltowerSection_register" : this.props.registerTheme === "commsChoice" ? "userlog_sec commsSection_register" : this.props.registerTheme === "onenet" ? "userlog_sec OneNet_register" : this.props.registerTheme === "mobex" ? "userlog_sec OneNet_register Mobex_register" : this.props.registerTheme === "oneuc" ? "userlog_sec OneNet_register Oneuc_register" :  this.props.registerTheme === "setup4" ? "userlog_sec setupRegister" : "userlog_sec register_section"}>
							<div className="container">
								<div className="login_box">
									<Logo />
									<p className="comp_reg text-center">Please complete your details to <br />register for YakChat</p>
									<div className="step_box text-center">
										<a href="#/register" className="step_btn">Step 1</a>
										<a href="javascript:;void(0);" className="step_btn ">Step 2</a>
										<a href="javascript:;void(0);l" className="step_btn ">Step 3</a>
										<a href="javascript:;void(0);" className="step_btn step_active">Step 4</a>
									</div>
									<div className="user_detail">
										<div className="register_scroll">
											<h3 className="user_head">Terms and Conditions</h3>
											<div className="terms_login">
												<label className="term_check">I have read and agree to the <u onClick={() => { window.open("https://yakchat.com/terms-of-service") }}>Terms of Service</u>  for using the YakChat service.
													<input type="checkbox" name="terms" checked={this.state.terms ? true : false}
														onChange={(e) => {
															if (e.target.checked) {
																this.setState({ terms: true });
															} else {
																this.setState({ terms: false });
																}
														}}
													/>
													<span className="checkmark"></span>
												</label>
												<label className="term_check">
													I agree to receive product updates and marketing Information in accordance with your <u onClick={() => { window.open(" https://yakchat.com/privacy-policy ") }}>Privacy Policy</u>.
													<input type="checkbox" name="policy" checked={this.state.policy ? true : false}
														onChange={(e) => {
															if (e.target.checked) {
																this.setState({ policy: true });
															} else {
																this.setState({ policy: false });
															}
														}} />
													<span className="checkmark"></span>
												</label>
											</div>
										</div>
										<div className="next_backbtn">
											<Link to="/registerDetails">
												<button className="nxtBtn back_btn">Back</button>
											</Link>
											<div className="register_cancel_btn">
												<Link to={this.props.registerTheme === "calltower" ? "/registercalltower" : this.props.registerTheme === "commsChoice" ? "/registercomms" : this.props.registerTheme === "mobex" ? "/mobexRegister": this.props.registerTheme === "oneuc" ? "/oneucRegister": "/"}>
													<button onClick={this.handelCancel} className="btn cstm_btn blk_btn">Cancel</button>
												</Link>
												{this.state.policy ? this.state.terms ? this.state.disabled ?
													<button onClick={this.handelSaveRegistration} className="nxtBtn registerNext">Finish</button> :
													<button className={"disable_btn_nxt registerNext"} >Finish</button> : <button className={"disable_btn_nxt registerNext"} >Finish</button>: <button className={"disable_btn_nxt registerNext"} >Finish</button> }
											</div>
										</div>
										{/* <Link to={this.props.registerTheme === "calltower" ? "/registercalltower" : this.props.registerTheme === "commsChoice" ? "/registercomms" : "/"}>
											<button id="redirectLogin" style={{ display: "none" }}>Finish</button>
										</Link> */}
									</div>
								</div>
							</div>
						</div>
				}

			</>
		);
	}
}
const mapStateToProps = (props: any) => {
	return {
		accessToken: props.reduxReducer.accessTokenApi,
		subscriptionDetails_registeration: props.reduxReducer.subscriptionDetails_registeration,
		userDetails_registeration: props.reduxReducer.userDetails_registeration,
		companyDetails_registeration: props.reduxReducer.companyDetails_registeration,
		registerTheme: props.reduxReducer.registerTheme,
		isNumber: props.reduxReducer.isNumber,
	};
};
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, null, any>, props: any) => {

	return {
		registerCompanyTrail: (company: any, subscription: any, user: any, accessToken: any) => {
			let companyVersion=store.getState().reduxReducer.companyVersion;
            if(companyVersion===2)
              {
				dispatch<any>(actions.registerCompanyTrail_V2(company, subscription, user, accessToken));
			  }
			  else
			  {
				dispatch<any>(actions.registerCompanyTrail(company, subscription, user, accessToken));
			  }
			
		},
		cancelBtnMailChimp: (company: any, subscription: any, user: any, accessToken: any) => {

			dispatch<any>(actions.cancelBtnMailChimp(company, subscription, user, accessToken));
		},
		cancelBtnZoho: (company: any, subscription: any, user: any, accessToken: any) => {

			dispatch<any>(actions.cancelBtnZoho(company, subscription, user, accessToken));
		},
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(RegisterTerms);