import React from "react";
import { connect, } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import logo from "../../../TeamsApp/images/mobexlogo.svg";
import { Link } from "react-router-dom";
import * as actions from "../../redux/actions/actions";
import * as msTeams from '@microsoft/teams-js';
import jwt_decode from "jwt-decode";

class RegisterMobex extends React.Component<any, any> {
    state = {
        PhoneNumber: "",
        fullname: "",
        email: "",
        accessTokenApi: ""
    };
    componentDidMount() {
        let fullName;
        let email;
        let PhoneNumber;
        let msEmail: any;
        if (this.props.userData !== undefined) {
            fullName = this.props.userData?.fullname;
            email = this.props.userData?.email;
            PhoneNumber = this.props.userData?.PhoneNumber;
        }
        this.setState({ PhoneNumber: PhoneNumber, fullname: fullName, email: email })
        msTeams.initialize(() => {
            
            var isInTeams = true;
            msTeams.getContext(async (c) => {
                
                msTeams.authentication.getAuthToken({
                    successCallback: async function (result) {
                        const tokenDecoded: any = jwt_decode(result);
                        msEmail = tokenDecoded.upn;
                    },
                    failureCallback: function (error) {
                        console.log("Failure: " + error);
                    }
                })
                setTimeout(() => {
                    this.setState({ email: msEmail })
                }, 100);

            })
        })
    }
    handelUser = () => {
        this.props.setRegisterTheme("mobex");
        var userdetailObj = {
            fullname: this.state.fullname,
            email: this.state.email,
            PhoneNumber: this.state.PhoneNumber,
        };
        this.props.setUserDetails(userdetailObj, this.state.accessTokenApi)
    }
    static getDerivedStateFromProps(nextProps: any, prevState: any) {
        if (nextProps.accessTokenApi !== prevState.accessTokenApi)
            return {
                accessTokenApi: nextProps.accessTokenApi,
            }
        else return null;
    }
    render() {

        return (
            <div className="userlog_sec OneNet_register Mobex_register" >
                <div className="container">
                    <div className="login_box">
                        <div className="login_logo text-center">
                            <img src={logo} />
                        </div>
                        <p className="comp_reg text-center">Please complete your details to <br />register for YakChat</p>
                        <div className="step_box text-center">
                            <a href="#/register" className="step_btn step_active">Step 1</a>
                            <a className="step_btn ">Step 2</a>
                            <a className="step_btn ">Step 3</a>
                            <a className="step_btn">Step 4</a>
                        </div>
                        <div className="user_detail" >
                            <div className="register_scroll">
                                <h3 className="user_head">User Details</h3>
                                <form>
                                    <div className="mb-3">
                                        <label className="form-label">Full name</label>
                                        <input type="text" className="form-control"
                                            value={this.state.fullname}
                                            onChange={(e) => this.setState({ fullname: e.target.value })}
                                            placeholder="Enter your full name" />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">E-mail address</label>
                                        <input type="email" className="form-control"
                                            value={this.state.email}
                                            onChange={(e) => this.setState({ email: e.target.value })}
                                            placeholder="Enter your Microsoft 365 E-mail address" />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Phone number</label>
                                        <input type="text" className="form-control"
                                            value={this.state.PhoneNumber}
                                            onChange={(e) => this.setState({ PhoneNumber: e.target.value })}
                                            placeholder="Enter your mobile/cell phone number" />
                                    </div>
                                </form>
                            </div>
                            <Link to="/registerUser">
                                <button onClick={this.handelUser} className="nxtBtn" >Next</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (props: any) => {
    return {
        //   loggedInUser: props.reduxReducer.loggedInUser,
        user: props.reduxReducer.user,
        accessTokenApi: props.reduxReducer.accessTokenApi,
        userData: props.reduxReducer.userDetails_registeration,
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, null, any>, props: any) => {
    return {
        setUserDetails: (userdetailObj: any, accessTokenApi: any) => {
            dispatch<any>(actions.setUserDetails(userdetailObj, accessTokenApi));
        },
        setRegisterTheme: (registerTheme: any) => {
            dispatch<any>(actions.setRegisterTheme(registerTheme));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(RegisterMobex);