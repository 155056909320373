import React from "react";
import AuthService from "../../../Config/msalConfig";
import { connect } from "react-redux";
import ErrorLogo from "./errorlogo";


class ErrorBoundary extends React.Component<any, any> {
    state = {
        hasError: false,
        error: { message: '', stack: '' },
        info: { componentStack: '' }
      };
  static getDerivedStateFromError(error: any) {return { hasError: true };
  }
  componentDidCatch(error: any, info: any) {  
  }
  render() {
    if (this.state.hasError) {
      return (
        <section className="userlog_sec error_sec" style={{backgroundColor:this.props.whiteLabelBrand === "calltower" || window.location.href.toLowerCase().includes("calltower")? "#6bbc15" :
         this.props.whiteLabelBrand ===  "commschoice" || window.location.href.toLowerCase().includes("commschoice") ? "#000045" :
         this.props.whiteLabelBrand ===  "onenet" || window.location.href.toLowerCase().includes("onenet") ? "#E9E9ED":
         this.props.whiteLabelBrand ===  "mobex" || window.location.href.toLowerCase().includes("mobex") ? "#E5E5E5":
         this.props.whiteLabelBrand ===  "oneuc" || window.location.href.toLowerCase().includes("oneuc") ? "#F43C00":"#fcbf00"}}>
          <div className="container" style={{display:"flex",justifyContent:"center",alignItems:'center'}}>
            <div className="login_box" style={{ textAlign: "center" }}>
              <div className="error_logo text-center" style={{maxWidth:this.props.whiteLabelBrand ===  "mobex" || window.location.href.toLowerCase().includes("mobex") ? "225px" 
              :this.props.whiteLabelBrand ===  "oneuc" || window.location.href.toLowerCase().includes("oneuc") ? "225px" :this.props.whiteLabelBrand === "onenet" || window.location.href.toLowerCase().includes("onenet") ?"250px" :"150px"}}>
             <ErrorLogo/>
              </div>
              <div className="timeOutBox">
                <div className="inner_box">
                <h1>{this.props.trailExpier === "SystemError" ? "Oops! We’re unable to connect to the service" :this.props.trailExpier==="DomainValidation"?"Please contact info@yakchat.com to approve registering a trial using this domain.":this.props.trailExpier==="maxAttempt"?"Please contact info@yakchat.com to register max check attempts reached.":"Lost connection with your profile."}</h1>
                  <p className="subheading">
                  {this.props.trailExpier === "SystemError" ?"Please refresh the application. If this problem persists, please contact info@yakchat.com" :this.props.trailExpier==="DomainValidation"?"Please contact info@yakchat.com to approve registering a trial using this domain.":this.props.trailExpier==="maxAttempt"?"Please contact info@yakchat.com to register max check attempts reached.":  "Please refresh to reconnect with your Microsoft profile."}
                  </p>  
             <div style={{justifyContent:"center",display:"flex",alignItems:"center"}}>
             <button    
                className="btn cstm_btn blk_btn"
                onClick={() => {
                    AuthService.initSignin();
                  }}
                style={{
                  marginTop: "20px",
                  backgroundColor:this.props.whiteLabelBrand === "calltower" || window.location.href.toLowerCase().includes("calltower")? "#6bbc15" : this.props.whiteLabelBrand ===  "commschoice" || window.location.href.toLowerCase().includes("commschoice") ? "#000045" : this.props.whiteLabelBrand ===  "onenet" || window.location.href.toLowerCase().includes("Onenet") ? "#BECE31" :this.props.whiteLabelBrand ===  "mobex" || window.location.href.toLowerCase().includes("mobex") ? "#4555AC"
                  :this.props.whiteLabelBrand ===  "oneuc" || window.location.href.toLowerCase().includes("oneuc") ? "#482474"  :"#fcbf00",
                  border:"none",
                  color:"#fff",
                }}
              >
               Refresh
              </button>
             </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    }
    return this.props.children;
  }
}
const mapStateToProps = (props: any) => {
return {
 whiteLabelBrand:props.reduxReducer.whiteLabelBrand,
 trailExpier:props.reduxReducer.trailExpier
};
};
export default connect(mapStateToProps,null)(ErrorBoundary);

