import React from "react";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import * as action from "../../../redux/actions/actions";
import * as commonMethod from "../../../commonMethods";
import { ReactComponent as MinusOutlineSvg } from "../../../images/minus-icon-ouline-orange.svg";
import { ReactComponent as AddToGroupIcon } from "../../../images/group-add-icon.svg";
import { ReactComponent as BackIcon } from "../../../images/left_arrow.svg";
import { Select } from "antd";
import { store } from "../../../..";
class NewGroup extends React.Component<any, any> {
  state = {
    groupName: "",
    groupEmail: "",
  };
  handleChange = (selectedItems: any) => {
    this.setState({groupEmail: selectedItems[0]});
    
  };
  removeFromSelected_newMessage(c: any): void {
    const newSelectedContacts: [any] = JSON.parse(
      JSON.stringify(this.props.selectedContactsNewMessage)
    );
    const index = newSelectedContacts.findIndex((x: any) => {
      if (
        c.type === "YakChat" &&
        x.type === "YakChat" &&
        c.ContactId === x.ContactId
      )
        return true;
    });
    newSelectedContacts.splice(index, 1);
    this.props.setSelectedContacts(newSelectedContacts);
  }
  render() {
    const groupEmailTagSuggestions =
    this.props.yakChatGroupData &&
    this.props.yakChatGroupData.map((i: any) => {
      // return { email: i.Email, id: JSON.stringify(x), text: i.UserName };
      let companyVersion=store.getState().reduxReducer.companyVersion;
      if(companyVersion===2)
        {
          return { label: i.Name, value: i.InboxId };
        }
        else{
      return { label: i.UserName, value: i.UserName };
        }
    });
    
    return (
      this.props.selectedContactsNewMessage && (
        <div className="right_Sec" id="right_Sec_main">
          <div className="top_row">
            <div className="top_left">
              <div className="usr_imgcon">
                <a
                  href="javascript:;void(0);"
                  className="left_arrow blk_icon"
                  onClick={() => this.props.setTabOpened("")}
                >
                  <BackIcon id="newMessage-backArrow" className="blk_icon" />
                </a>
                <div className="usr_txt">
                  <h3>New Group </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="chat_or_info">
            <div className="chat_sec">
              <div className="chat_bx">
                <div className="cntct_frm cntct_view contct_upl">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="cntcts_grp">
                        <div className="cntct_hedr">
                          <h2>Contacts in group</h2>
                          <span className="sub_txt">
                            {this.props.selectedContactsNewMessage?.length}{" "}
                            contacts
                          </span>
                        </div>
                        <ul className="usr_list import_contactbox">
                          {this.props.selectedContactsNewMessage.map(
                            (c: any, i: number) => {
                              return (
                                <li key={i}>
                                  <div className="usr_imgbx">
                                    <div className="usr_img2">
                                      {commonMethod.generateFirstLastNameIntials(
                                        c.ForeName + " " + c.SureName
                                      )}
                                    </div>
                                  </div>
                                  <div
                                    className="usr_txt"
                                    style={{ display: "flex" }}
                                  >
                                    <div>
                                      <h3
                                        style={{
                                          whiteSpace: "pre-wrap",
                                          textAlign: "left",
                                          paddingRight: "5px",
                                          wordBreak: "break-word",
                                        }}
                                      >
                                        {c.ForeName} {c.SureName !== null ? c.SureName :"" }
                                      </h3>
                                      <div className="txt_div">
                                        <span className="sub_txt">
                                          {c.PhoneNumber}
                                        </span>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        flex: 1,
                                        alignSelf: "center",
                                        float: "right",
                                        textAlign: "right",
                                      }}
                                    >
                                      {" "}
                                      <MinusOutlineSvg  className= "ctThem"
                                        onClick={() =>
                                          this.removeFromSelected_newMessage(c)
                                        }
                                      />
                                    </div>

                                    {/* <a
                                          href="#"
                                          className="add_btn ylw_bg"
                                          onClick={() => {
                                            const newContactsToBeSaved = this.state.contactsToBeSaved.filter(
                                              (c: any, j: number) => i !== j
                                            );
                                            this.setState({
                                              contactsToBeSaved: newContactsToBeSaved,
                                            });
                                          }}
                                        >
                                          {" "}
                                          <img
                                            src="images/minus.svg"
                                            alt="Add"
                                          />{" "}
                                        </a> */}
                                  </div>
                                </li>
                              );
                            }
                          )}
                          {/* <li>
                            <div className="usr_imgbx">
                              <div className="usr_img2"> LC </div>
                            </div>
                            <div className="usr_txt">
                              <h3> Saul Bloom</h3>
                              <div className="txt_div">
                                <span className="sub_txt">
                                  +(1) 224 456 544
                                </span>
                              </div>
                              <a href="#" className="add_btn ylw_bg">
                                {" "}
                                <img src="images/minus.svg" alt="Add" />{" "}
                              </a>
                            </div>
                          </li>
                          <li>
                            <div className="usr_imgbx">
                              <div className="usr_img2 light_orng"> FC </div>
                            </div>
                            <div className="usr_txt">
                              <h3> Frank Catton</h3>
                              <div className="txt_div">
                                <span className="sub_txt">
                                  +(1) 224 456 544{" "}
                                </span>
                              </div>
                              <a href="#" className="add_btn ylw_bg">
                                {" "}
                                <img src="images/minus.svg" alt="Add" />{" "}
                              </a>
                            </div>
                          </li> */}
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="grp_info">
                        <div className="cntct_hedr">
                          <h2> Group Information</h2>
                        </div>
                        <div className="mb-3">
                          <label className="form-label">GROUP NAME</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Group Name"
                            value={this.state.groupName}
                            maxLength={50}
                            onChange={(e) => {
                              e.preventDefault();
                              this.setState({ groupName: e.target.value });
                            }}
                          />
                        </div>
                    
                        {this.props.ServicePlanId === 1 || this.props.ServicePlanId === 3 ? "" :
                        <div className="mb-3">
                          <label className="form-label">
                            Shared with :
                          </label>
                          <Select
                                className="sharedwith-dropdown"
                                mode="multiple"
                                placeholder="Start typing inbox name..."
                                // value={this.state.groupEmail}
                                onChange={ this.handleChange}
                                filterOption={(v, option) => {
                                  v = v.toLowerCase();
                                  const a: any = option;
                                  return (
                                    a?.label?.toLowerCase().includes(v) ||
                                    a?.value?.toLowerCase().includes(v)
                                  );
                                }}
                                style={{ width: "100%" }}
                                options={groupEmailTagSuggestions}
                              ></Select>
                        </div>
  }
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="drag_btnbox">
                <a
                  href="javascript:;void(0);"
                  className="drag_btn cancel_btn"
                  onClick={(e) => {
                    e.preventDefault();
                    document.getElementById("msg_tab")?.click();
                    this.props.setSelectedContacts([]);
                    this.props.setTabOpened("view Group");
                   
                  }}
                >
                  Cancel
                </a>
                <a
                  href="javascript:;void(0);"
                  className="drag_btn next_btn next_yellow"
                  onClick={(e) => {
                    e.preventDefault();
                    let companyVersion=store.getState().reduxReducer.companyVersion;
                    if(companyVersion==2)
                    {
                      var sharedEmail;
                      debugger;
                      if(this.state.groupEmail !== undefined){
                        sharedEmail = this.props.yakChatGroupData?.find((c:any) => {if (c.InboxId === Number(this.state.groupEmail)) {return (c.InboxId)} })
                      }
                     
                      sharedEmail = sharedEmail !== undefined ? sharedEmail.InboxId : ""
                      this.props.createRoomGroup( this.state.groupName, this.props.selectedContactsNewMessage,sharedEmail  )
                    }
                    else{
                    var sharedEmail;
                    if(this.state.groupEmail !== undefined){
                      sharedEmail = this.props.yakChatGroupData?.find((c:any) => {if (c.UserName === this.state.groupEmail) {return (c.Email)} })
                    }
                    sharedEmail = sharedEmail !== undefined ? sharedEmail.Email : ""
                    this.props.createRoomGroup( this.state.groupName, this.props.selectedContactsNewMessage,sharedEmail  )
                  }
                  }
                  }
                    >
                  <AddToGroupIcon className="blk_icon" style={{ fill: "var(--surface)" }} ></AddToGroupIcon>
                  {/* <img src="images/group-add-icon.svg" alt="" /> */}
                  &nbsp;&nbsp;Create group
                </a>
              </div>
            </div>
          
          </div>
        </div>
      )
    );
  }
}
const mapStateToProps = (props: any) => {
  let companyVersion=store.getState().reduxReducer.companyVersion;
  return {
    selectedContactsNewMessage:
      props.reduxReducer.selectedContactsNewMessage || [],
      groupData: props.reduxReducer.groupCurrentlyEditing,
      yakChatGroupData:companyVersion!==2? props.reduxReducer.yakChatGroupData:props.reduxReducer.setSharedEmails,
      ServicePlanId: props.reduxReducer.ServicePlanId,
  };
};
const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, null, any>,
  props: any
) => {
  return {
    setAlertData: (data: any) => {
      dispatch<any>(action.setAlertData(data));
    },
    setSelectedContacts: (contact: any) => {
      dispatch<any>(action.setSelectedContacts(contact));
    },
    createRoomGroup: ( groupName: string,newContacts: any,groupEmail: string) => {
      let companyVersion=store.getState().reduxReducer.companyVersion;
      if(companyVersion===2)
        {
      dispatch<any>(action.AddContactList_V2(groupName, newContacts, groupEmail));
        }
        else
        {
          dispatch<any>(action.AddContactList(groupName, newContacts, groupEmail));
        }
    },
    setTabOpened: (tab: string) => {
      dispatch<any>(action.setTabOpened(tab));
    },
    setGroupCurrentlyEditing: async (group: any, lists: any, calledFrom: any, contacts: any) => {
      let companyVersion=store.getState().reduxReducer.companyVersion;
      if(companyVersion===2)
        {
      dispatch<any>(action.getContactsByListId_V2(group, lists, "", []));
        }
        else
        {
          dispatch<any>(action.getContactsByListId(group, lists, "", []));
        }
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(NewGroup);
