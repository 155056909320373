import { debug } from "console";
import { store } from "../../index";
import configData from "../../appsettings.json";
import { stat } from "fs";
export const getOutlookcontactfolders = () => {
  const url = `${configData.graphApiUrl}/v1.0/me/contactFolders/`;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization: "Bearer " + store.getState().reduxReducer.loggedInUser.accessToken,
    },
  });
};
export const getoutlookContactList = (pageIndex: number) => {
  const url = `${configData.graphApiUrl}/v1.0/me/contacts?$top=${999}&$skip=${(pageIndex - 1) * 999}&$orderby=displayName`;

  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:"Bearer " + store.getState().reduxReducer.loggedInUser.accessToken,
    },
  });
};
export const getOutlookcontactlistByContactFolders = (id: any) => {
  const url = `${configData.graphApiUrl}/v1.0/me/contactFolders/` + id + "/contacts";
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization: "Bearer " + store.getState().reduxReducer.loggedInUser.accessToken,
    },
  });
};
export const editOutlookContact = (id: any, data: any) => {
  const url = `${configData.graphApiUrl}v1.0/me/contacts/` + id;
  return fetch(url, {
    method: "PATCH",
    headers: {
      Accept: "*/*",
      "Content-Type": "application/json",
      Authorization:"Bearer " + store.getState().reduxReducer.loggedInUser.accessToken,
    },
    body: JSON.stringify(data),
  });
};
export const searchoutlookContactList = ( searchQuery: string,pageIndex: number) => {
  const url = `${configData.graphApiUrl}/v1.0/me/contacts?$top=${10}&$skip=${(pageIndex - 1) * 999}&$filter=startswith(displayName,'${searchQuery}') or startswith(givenName,'${searchQuery}') or startswith(surname,'${searchQuery}')&$orderby=displayName`;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization: "Bearer " + store.getState().reduxReducer.loggedInUser.accessToken,
    },
  });
};
export const getsharepointContactsLists = (pageIndex: number) => {
  // console.log(pageIndex)
  //debugger;
  //const url = `${configData.graphApiUrl}/v1.0/sites/root/lists/?$top=${10000}&$skiptoken=${(10) *pageIndex}&$paged=${true}&$orderby=displayName`;
  const url = `${configData.graphApiUrl}/v1.0/sites/root/lists/?$top=${10000}&$orderby=displayName`;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization: "Bearer " + store.getState().reduxReducer.loggedInUser.accessToken,
    },
  });
};
export const getsharepointContactsListItems = (name: any,pageIndex:any) => {

  const url = `${configData.graphApiUrl}/v1.0/sites/root/lists/`+ name + `/items?$top=${20 * (pageIndex)}&$offset=${(pageIndex-1)*10}&$expand=fields`;
  return fetch(url, {
    method: "GET",
    headers: {
     
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:"Bearer " + store.getState().reduxReducer.loggedInUser.accessToken,
    },
  });
};
export const searchSharepointContactsItems = (  name: any,searchQuery: string,pageIndex: number) => {
  const url = `${configData.graphApiUrl}/v1.0/sites/root/lists/` + name + `/items?$top=${10000}&$expand=fields`;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:"Bearer " + store.getState().reduxReducer.loggedInUser.accessToken,
    },
  });
};
export const editSharePointContact = (itemId: any, listId: any, data: any) => {
  const url = `${configData.graphApiUrl}/v1.0/sites/root/lists/${listId}/items/${itemId}`;
  return fetch(url, {
    method: "PATCH",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:"Bearer " + store.getState().reduxReducer.loggedInUser.accessToken,
    },
    body: JSON.stringify(data),
  });
};
export const getsharepointContactsListItemsItem = (id: any, name: any) => {
  const url = `${configData.graphApiUrl}/v1.0/sites/root/lists/${name}/items/${id}/?expand=fields`;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization: "Bearer " + store.getState().reduxReducer.loggedInUser.accessToken,
    },
  });
};
export const getyakchatcontactlist = (pageindex: any,userEmail:any) => {
  
  if(userEmail.charAt(userEmail?.length-1) === ","){
     userEmail = userEmail.replace(/,/gi, "");
  }
  const url = `${configData.APiUrl}/LocalContacts/GetPagingContactsByUserEmail?userEmail=` + userEmail + `&pageIndex=` + pageindex;
    return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:"Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
  });
};
export const getContactsByListId = (listId: any) => {
  const url =`${configData.APiUrl}/Lists/GetLocalContactsByListId?id=` +  listId;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:"Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
  });
};
export const searchyakchatcontactlist = (pageindex: any, searchFilter: any) => {
  searchFilter = encodeURIComponent(searchFilter);
  var sharedEmails =  store.getState().reduxReducer.shareMsgCount?.$values !== undefined ? store.getState().reduxReducer.shareMsgCount?.$values.map((a: any) => a.sharedEmail).toString() : store.getState().reduxReducer.shareMsgCount?.map((a: any) => a.sharedEmail).toString();
  var userEmail = encodeURIComponent( store.getState().reduxReducer.loggedInUser.account.userName);
  userEmail= userEmail + "," + sharedEmails;
  const url =`${configData.APiUrl}/LocalContacts/SearchContacts?userEmail=` + userEmail +`&pageIndex=` + pageindex +`&searchText=` +searchFilter;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
       Authorization:"Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi
         // "Access-Control-Allow-Origin": "*",
      // "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Authorization",
      // "Access-Control-Request-Method": "GET, POST, DELETE, PUT, OPTIONS",
      // mode: "no-cors",
    },
  });
};
export const getYakChatGroupData = () => {
  const url = `${configData.APiUrl}/Users/GetGroupsEmails`;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:"Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
  });
};
export const saveYakChatContact = async(data: any, savedFrom: any) => {
  let url = '';
  var phoneNumber= encodeURIComponent(data.phoneNumber);
  var userEmail = encodeURIComponent(store.getState().reduxReducer.loggedInUser.account.userName);
  var groupEmail = data.groupEmail === "" ? encodeURIComponent(data.groupEmail) : data.groupEmail;
  if(data.email === "" && groupEmail === "" && data.lastname === ""){
    url =await `${configData.APiUrl}/LocalContacts/InsertContacts?Email=""&Phone=${phoneNumber}&foreName=${data.firstName}&sureName=""&userEmail=${userEmail}&groupEmail=""`;
  }else  if(data.email === "" && groupEmail === ""){
    url =await `${configData.APiUrl}/LocalContacts/InsertContacts?Email=""&Phone=${phoneNumber}&foreName=${data.firstName}&sureName=${data.lastName}&userEmail=${userEmail}&groupEmail=""`;
  }else if(data.email === "" && groupEmail !== ""){
    url =await `${configData.APiUrl}/LocalContacts/InsertContacts?Email=""&Phone=${phoneNumber}&foreName=${data.firstName}&sureName=${data.lastName}&userEmail=${userEmail}&groupEmail=${groupEmail}`;
  }
  else if(groupEmail === "" && data.email !== "") {
    url =await `${configData.APiUrl}/LocalContacts/InsertContacts?Email=${data.email}&Phone=${phoneNumber}&foreName=${data.firstName}&sureName=${data.lastName}&userEmail=${userEmail}&groupEmail=""`;
  } else if(data.lastName === "") {
    url =await `${configData.APiUrl}/LocalContacts/InsertContacts?Email=${data.email}&Phone=${phoneNumber}&foreName=${data.firstName}&sureName=""&userEmail=${userEmail}&groupEmail=${groupEmail}`;
  } 
  else{
    url  = await`${configData.APiUrl}/LocalContacts/InsertContacts?Email=${data.email}&Phone=${phoneNumber}&foreName=${data.firstName}&sureName=${data.lastName}&userEmail=${userEmail}&groupEmail=${groupEmail}`;
  }
  return await fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization: "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
  }); 
};
export const uploadYakChatContact = async(data: any, savedFrom: any) => {
  // var phoneNumber= encodeURIComponent(data.phoneNumber);
  const url  = await`${configData.APiUrl}/LocalContacts/AddImportedContacts?Email`;
  return await fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization: "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },body: JSON.stringify(data)
  });  
};
export const UpdateRoomParticipantTeams = (data: any, participantId: any) => {
  var sharedEmails =  store.getState().reduxReducer.shareMsgCount?.$values !== undefined ? store.getState().reduxReducer.shareMsgCount?.$values.map((a: any) => a.sharedEmail).toString() : store.getState().reduxReducer.shareMsgCount?.map((a: any) => a.sharedEmail).toString();
  var userEmail = encodeURIComponent( store.getState().reduxReducer.loggedInUser.account.userName);
  data.groupEmail = userEmail + "," + sharedEmails;
  var url=""
  if(data.groupEmail === "" || data.groupEmail === null ){
     url = `${configData.APiUrl}/Rooms/UpdateRoomParticipant?userEmail=""&roomParticipantId=${participantId}&contactUri=${data.phoneNumber}&name=${data.firstName + " " + data.lastName}`;
  }
  url = `${configData.APiUrl}/Rooms/UpdateRoomParticipant?userEmail=${data.groupEmail}&roomParticipantId=${participantId}&contactUri=${data.phoneNumber}&name=${data.firstName + " " + data.lastName}`;
  return fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:"Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
  });
};
export const UpdateFirstReceiveConvName = (roomId: any) => {
  const url = `${configData.APiUrl}/Rooms/UpdateFirstReceiveConvName?roomId=${roomId}`;
  return fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization: "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    }
  });
};
export const unreadCounterUpdate=(roomId:any,lastIncomingMessageId:any,groupEmail:any)=>{
  const url = `${configData.APiUrl}/Rooms/UpdateLastSeen?roomId=${roomId}&lastSeen=${lastIncomingMessageId}&userEmail=${groupEmail}`;
  return fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:"Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
  });  
}
export const GetGroupRoomPrimaryMember=(roomId:any)=>{
  const url = `${configData.APiUrl}/Rooms/GetPrimaryMember?roomid=${roomId}`;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:"Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    }
  });  
}
export const GetContactsByUserEmailPhoneNumber=(userEmail:any,phoneNumber:any)=>{
  phoneNumber = phoneNumber?.replace("+", "%2B");
  const url = `${configData.APiUrl}/LocalContacts/GetContactsByUserEmailPhoneNumber?userEmail=${userEmail}&phoneNumber=${phoneNumber}`;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:"Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    }
  });  
}
export const editYakChatContact = (id: any, data: any) => {
  var url =""
  data.Phone = data.Phone.replace("+", "%2B");
  data.groupEmail = data?.groupEmail === "undefined" ? "" :data.groupEmail;
  if(data.Email === "" && data.groupEmail === "" && data.surename=== ""){
    url = `${configData.APiUrl}/LocalContacts/UpdateContacts?Phone=${data.Phone}&foreName=${data.foreName}&contactId=${id}`;
  }else  if(data.Email === "" && data.groupEmail === "" ){
    url = `${configData.APiUrl}/LocalContacts/UpdateContacts?Phone=${data.Phone}&foreName=${data.foreName}&sureName=${data.surename}&contactId=${id}`;}
  else if(data.groupEmail === ""){
    url = `${configData.APiUrl}/LocalContacts/UpdateContacts?Email=${data.Email}&Phone=${data.Phone}&foreName=${data.foreName}&sureName=${data.surename}&contactId=${id}`;
  }else  if(data.Email === ""){
    url = `${configData.APiUrl}/LocalContacts/UpdateContacts?Phone=${data.Phone}&foreName=${data.foreName}&sureName=${data.surename}&contactId=${id}&groupEmail=${data.groupEmail}`;
  }
  else{
     url = `${configData.APiUrl}/LocalContacts/UpdateContacts?Email=${data.Email}&Phone=${data.Phone}&foreName=${data.foreName}&sureName=${data.surename}&contactId=${id}&groupEmail=${data.groupEmail}`;
  }
  return fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:"Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    }
  });
};
export const getGroupsEmails = () => {
  const url = `${configData.APiUrl}/GetGroupsEmails`;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:"Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    }
  });
};
export const updateRoomParticipants = (roomParticipantId:any,contactUri:any,name:any,userEmail:any) => {
  const url = `${configData.APiUrl}/Rooms/UpdateRoomParticipant?userEmail=${userEmail}&roomParticipantId=${roomParticipantId}&contactUri=${contactUri}&name=${name}`;
  return fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:"Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    }
  });
};
export const getContactsByUserEmail = (userEmail: string,pageIndex:any) => {
  const url = `${configData.APiUrl}/GetContactsByUserEmail?Email=${userEmail}`;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:"Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    }
  });
};
export const getUsableCreditForUser = () => {
  const url = `${configData.APiUrl}/Users/getUsableCreditForUser?userEmail=${store.getState().reduxReducer.loggedInUser.account.userName}`;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization: "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    }
  });
};
export const saveYakChatSettings = (checkedvalue: string,isSharePoint: string) => {
  const url = `${configData.APiUrl}/Setting/ChangeContactType?userEmail=${store.getState().reduxReducer.loggedInUser.account.userName}&flag=${checkedvalue}&isSharePoint=${isSharePoint}`;
  return fetch(url, {
    method: "POST",
    headers: {    
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization: "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    }
  });
};
export const getRooms = (pageIndex: number, email: string) => {
  const url = `${configData.APiUrl}/getRoomsGroup?email=${email}`;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:  "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    }
  });
};
export const queueMessageChannel = (roomId: any, text: any,userEmail:any,fileBlob:any,serviceproviderid:any,channelName:any) => {
  debugger;
  text=text.replace(/%2520/g,"£_Yak");
  text=decodeURI(text);
  text=text.replace(/£_Yak/g,"%2520");
  text= text.replace(/\n/g,"%0A");
  text=text.replace(/&/g,"%26");
  const version ="React"
  if(text?.length === 0){ text="%00"}
  var SendRequest:any={roomId:roomId,text:text,userEmail:userEmail,uploadedFiles:fileBlob,serviceProviderId:serviceproviderid,channelName:channelName,yakchatVersion:version};

  const url = `${configData.APiUrl}/Sending/QueueMessage_Channel_V3`;
  return fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:"Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
    body: JSON.stringify(SendRequest),
  });
};
// export const queueMessage = (roomId: any, text: any,userEmail:any) => {
//   text= text. replace(/\n/g,"%0A");
//   const url = `${configData.APiUrl}/Sending/QueueMessage?roomId=${roomId}&text=${text}&userEmail=${userEmail}`;
//   return fetch(url, {
//     method: "POST",
//     headers: {
//       accept: "application/json;",
//       Authorization:"Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
//     }
//   });
// };
// export const queueMessageMMS = (roomId: any, text: any,fileBlob:any,fileName:any,userEmail:any,) => {
//   text=decodeURI(text);
//   text= text. replace(/\n/g,"%0A");
//   var messageMMS ={roomId:roomId, text:text, uploadedFileUrl :fileBlob,fileName:fileName, email:userEmail}
//   const url = `${configData.APiUrl}/Sending/QueueMessageMMS`;
//   return fetch(url, {
//     method: "POST",
//     headers: {
//       'Content-Type': 'application/json',
//       accept: "application/json;",
//       Authorization:
//         "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
//     },
//     body: JSON.stringify(messageMMS)
//   });
// };
// export const queueMessageMedia = (roomId: any, text: any,userEmail:any) => {
//   const url = `${configData.APiUrl}/Sending/QueueMessageMedia?roomId=${roomId}&text=${text}&userEmail=${userEmail}`;
//   return fetch(url, {
//     method: "POST",
//     headers: {
//       accept: "application/json;",
//       Authorization:
//         "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
//     }
//   });
// };
// export const queueMessageSMPP = (roomId: any, text: any,userEmail:any) => {
//   const url = `${configData.APiUrl}/Sending/QueueMessageSMPP?roomId=${roomId}&text=${text}&userEmail=${userEmail}`;
//   return fetch(url, {
//     method: "POST",
//     headers: {
//       accept: "application/json;",
//       Authorization:
//         "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
//     }
//   });
// };
// export const queueMessageVonageApi = (roomId: any, text: any,userEmail:any) => {
//   const url = `${configData.APiUrl}/Sending/QueueMessageVonageApi?roomId=${roomId}&text=${text}&userEmail=${userEmail}`;
//   return fetch(url, {
//     method: "POST",
//     headers: {
//       accept: "application/json;",
//       Authorization:
//         "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
//     }
//   });
// };
export const getUser = (token: string,userName:any) => {
  const url = `${configData.APiUrl}/Users/GetUsers_Trial?userEmail=`+userName;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      Authorization: "Bearer " + token,
    }
  });
};
export async function getUserCompany (companyId:any) {
  const url = `${configData.APiUrl}/Users/GetCompanyById?companyId=`+companyId;
  const response=await fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      Authorization:"Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    }
  });
  return response;
};
export const getMFAuser = (userEmail: string,groupEmail:any) => {
  const url = `${configData.APiUrl}/Sending/checkIfMFANumber?userEmail=${groupEmail !== undefined ? groupEmail : userEmail}`;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      Authorization: "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    }
  });
};
export const updateRoomsLatestMsgs = (token: string) => {
  const url = `${configData.APiUrl}/Rooms/UpdateRoomsLatestMsgs`;
  return fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;", Authorization: "Bearer " + token
    }
  });
};
export const GetServicePhoneNumbers = (phoneNumber: any, token: string) => {
  phoneNumber = phoneNumber.replace("+", "%2B");
  const url = `${configData.APiUrl}/Sending/GetServicePhoneNumbers?phoneNumbers=${phoneNumber}`;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      Authorization: "Bearer " + token,
    }
  });
};
export const GetGroupsEmails = () => {
  const url = `${configData.APiUrl}/GetGroupsEmails?userEmail=${store.getState().reduxReducer.loggedInUser.account.userName}`;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    }
  });
};
export const GetPersonalInbox = (pageIndex: number,userEmail:any,archiveFlag:any,groupemail:any) => {
  const url = `${configData.APiUrl}/Rooms/GetPagingUnArchivedRooms_New?userEmail=${userEmail}&archiveFlag=${archiveFlag}&PageIndex=${pageIndex}&groupEmail=${groupemail}`;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      Authorization: "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    }
  });
};
export const AddContactList = (participants: any) => {
  const url = `${configData.APiUrl}/Lists/AddContactList`;
  return fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:"Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
    body: JSON.stringify(participants),
  });
};
export const AddContactListsLocalContact = (contactId: number,listId: number) => {
  const url = `${configData.APiUrl}/Lists/AddContactListsLocalContact?contactIds=${contactId}&Id=${listId}&userEmail=${
    store.getState().reduxReducer.loggedInUser.account.userName
  }`;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization: "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    }
  });
};
export const removeContactListsLocalContact = ( contactId: number,listId: any,email: string) => {
  const url = `${configData.APiUrl}/Lists/RemoveContactListsLocalContact?contactIds=` + contactId + `&Id=` +listId +`&userEmail=` + email;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    }
  });
};
export const GetContactLists = (email:any) => {
  const url = `${configData.APiUrl}/Lists/GetLists?userEmail=${email}`;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    }
  });
};
export const DeleteThisList = (id: string) => {
  const url = `${configData.APiUrl}/Lists/DeleteContactList?listId=${id}`;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:  "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    }
  });
};
export const DeleteThisContact = (id: string, phoneNumber: any) => {
  const url = `${configData.APiUrl}/LocalContacts/DeleteContacts?contactId=${id}&phoneNumber=${phoneNumber}`;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:"Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    }
  });
};
export const GetUsersBySubscriptionId = (userEmail:string,id: number, token: string = store.getState().reduxReducer.loggedInUser.accessTokenApi,shouldCheckGroup: boolean = false) => {
  const url = `${configData.APiUrl}/Users/GetUsers_Trial?userEmail=${userEmail}&CheckGroup=${shouldCheckGroup}`;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    }
  });
};
export const UpdateGroupPrimaryOwner = (id: number, email: string) => {
  const url = `${configData.APiUrl}/Rooms/UpdateGroupPrimary?roomId=${id}&memberEmail=${email}`;
  return fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization: "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    }
  });
};
export async function CreateRoom(data: any,userEmail:any)  {
  
  let dataReturned;
const url = `${configData.APiUrl}/Rooms/CreateRoom?userEmail=${userEmail}&dedicated=false`;
 await fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:"Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
    body: JSON.stringify(data),
  })
  .then(async function(response) {                      // first then()
    if(response.ok)
    {
      dataReturned=response.text();       
      return dataReturned;  
    }

    throw new Error(await response.text());
})  
.then(function(text) {                          // second then()
  console.log('Request successful', text); 
  dataReturned=text;       
      return dataReturned;  
})  
.catch(function(error) {   
  dataReturned=error.toString().substring(
    error.toString().indexOf("(") + 1, 
    error.toString().indexOf(")"));
      console.log('Request failed',dataReturned);      
    return dataReturned;
});
return dataReturned;
};
export async function createRoomGroup (data: any, email: any) {
  let dataReturned;
  const url = `${configData.APiUrl}/Rooms/CreateRoomGroup?groupEmail=${email}&dedicated=${false}`;
  await fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:"Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
    body: JSON.stringify(data),
  }) .then(async function(response) {                      // first then()
    if(response.ok)
    {
      dataReturned=response.text();       
      return dataReturned;  
    }

    throw new Error(await response.text());
})  
.then(function(text) {                          // second then()
  console.log('Request successful', text); 
  dataReturned=text;       
      return dataReturned;  
})  
.catch(function(error) {   
  dataReturned=error.toString().substring(
    error.toString().indexOf("(") + 1, 
    error.toString().indexOf(")"));
      console.log('Request failed',dataReturned);      
    return dataReturned;
});
return dataReturned;
};
export const CloseConversation = (roomId: any, flag: any) => {
  const url = `${configData.APiUrl}/Rooms/ArchiveRooms?roomId=${roomId}&archiveFlag=${flag}`;
  return fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:"Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    }
  });
}
export const getPagingMessagesByRoomId = (userEmail:any,roomId: any, pageIndex: any) => {
    const url = `${configData.APiUrl}/Rooms/GetPagingMessagesByRoomId?userEmail=${userEmail}&roomId=${roomId}&pageIndex=${pageIndex}`;
    return fetch(url, {
      method: "GET",
      headers: {
        accept: "application/json;",
        "Content-Type": "application/json",
        Authorization:"Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
      }
    });
};
export const getOrderedUnArchivedRoom =(userEmail:any)=>{
  const url = `${configData.APiUrl}/Rooms/GetOrderedUnArchivedRooms?userEmail=${userEmail}`;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:"Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    }
  });
}
export  const uploadImageToBlob =async(fileBlobDetails:any)=>{
  const url = await `${configData.APiUrl}/V3/Send/AddMessageMedia`;
  return await fetch  (url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:"Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,      
    },body:JSON.stringify(fileBlobDetails)
  });
}
export  const searchConversation =async(archiveFlag: any, searchText: any, groupEmail: any, userEmail: any)=>{
  const url = await `${configData.APiUrl}/Rooms/SearchRooms?archiveFlag=${archiveFlag}&searchText=${searchText}&groupEmail=${groupEmail}&userEmail=${userEmail}`;
  return await fetch  (url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:"Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,      
    },
  });
}
export  const getSasTokenBlobs =async()=>{
  const url = await `${configData.APiUrl}/Sending/GetSasTokenBlobs`;
  return await fetch  (url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:"Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,      
    },
  });
}

export  const registerUserTrail =async(user:any,stradditionalEmails:any,groupEmail:any,dedicatedNumber:any,accessToken:any,userData:any)=>{
  const url = await `${configData.APiUrl}/Users/AddUser_Trial?stradditionalEmails=${user}&groupEmail=${stradditionalEmails}&groupName=${groupEmail}&dedicatedNumber=${dedicatedNumber}`;
  return await fetch  (url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:"Bearer " + accessToken,      
    },body:JSON.stringify(userData)
  });
}

export  const registerCompanyTrail =async(company:any,accessToken:any)=>{
  const url = await `${configData.APiUrl}/Users/AddCompany_Trial`;
  return await fetch  (url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:"Bearer " + accessToken,      
    },body:JSON.stringify(company)
  });
}

export  const registerSubscriptionTrail =async(subscription:any,accessToken:any)=>{
  const url = await `${configData.APiUrl}/Users/AddSubscription_Trial`;
  return await fetch  (url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:"Bearer " + accessToken,      
    },body:JSON.stringify(subscription)
  });
}
export  const servicePhoneNumberUpdateTrail =async(PhoneNumber:any, trail:any,accessToken:any)=>{
const url = await `${configData.APiUrl}/ServicePhoneNumber/updateTrialServicePhonenumber?phoneNumber=${PhoneNumber}&trial=${trail}`;
return await fetch  (url, {
  method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:"Bearer " + accessToken,      
    },
  });
}
export  async function servicePhoneNumberGetTrail (accessToken:any){
  
    const url = `${configData.APiUrl}/ServicePhoneNumber/GetBandWidthPhoneNumbers?trial=${true}`;
    const data= await fetch  (url, {
      method: "GET",
      headers: {
        accept: "application/json;",
        "Content-Type": "application/json",
        Authorization:"Bearer " + accessToken,      
      },
    });
    return data;
  }
export const saveProfileMode = (userEmail:any,displayMode:any) => {
  const url = `${configData.APiUrl}/Setting/saveProfileMode?userEmail=${userEmail}&displayMode=${displayMode}`;
  return fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    }
  });
};

export const addSharedContactApi = (lstContact:any) => {
  const url = `${configData.APiUrl}/LocalContacts/AddImportedContacts`;
  return fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },body:JSON.stringify(lstContact)
  });
};

export  const getUserByEmail =async(userEmail:any)=>{
  const url = await `${configData.APiUrl}/Users/GetUserByUserEmail?userEmail=${userEmail}`;
  return await fetch  (url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:"Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,      
    },
  });
}
export  const registerUserToMailChimp =async(userEmail:any,userRegistrationDate:any,servicePlanId:any,firstname:any,lastname:any,street:any,city:any,phone:any,state:any,country:any,dedecatedNumber:any,accessToken:any)=>{ 
  
  const url = await `${configData.APiUrl}/MailChimp/RegisterUsertoMailChimp?userEmail=${userEmail}&userRegistrationDate=${userRegistrationDate}&servicePlanId=${servicePlanId}&firstname=${firstname}&lastname=${lastname}&street=${street}&city=${city}&phone=${phone}&state=${state}&country=${country}&dedecatedNumber=${dedecatedNumber}`;
  return await fetch  (url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:"Bearer " + accessToken, 
    },
  });
}
export  const cancelUserToMailChimp =async(userEmail:any,userRegistrationDate:any,servicePlanId:any,firstname:any,lastname:any,street:any,city:any,phone:any,state:any,country:any,dedecatedNumber:any,accessToken:any)=>{  
const url = await `${configData.APiUrl}/MailChimp/CancelUsertoMailChimp?userEmail=${userEmail}&userRegistrationDate=${userRegistrationDate}&servicePlanId=${servicePlanId}&firstname=${firstname}&lastname=${lastname}&street=${street}&city=${city}&phone=${phone}&state=${state}&country=${country}&dedecatedNumber=${dedecatedNumber}`;
  return await fetch  (url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:"Bearer " + accessToken, 
    },
  });
}

export  const sendNewMsgMailChimp =async(userEmail:any,)=>{
  const url = await `${configData.APiUrl}/MailChimp/SendNewMsgMailChimp?userEmail=${userEmail}`;
  return await fetch  (url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:"Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi, 
    },
  });
}

export  const registerUserToZoho =async(userEmail:any,company:any,userRegistrationDate:any,servicePlanId:any,firstname:any,lastname:any,street:any,city:any,phone:any,state:any,country:any,zip:any,dedecatedNumber:any,accessToken:any)=>{ 
  
  const url = `${configData.APiUrl}/Zoho/RegisterUsertoZoho?userEmail=${userEmail}&company=${company}&userRegistrationDate=${userRegistrationDate}&servicePlanId=${servicePlanId}&firstname=${firstname}&lastname=${lastname}&street=${street}&city=${city}&phone=${phone}&state=${state}&country=${country}&zip=${zip}&dedecatedNumber=${dedecatedNumber}`;
  return await fetch  (url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:"Bearer " + accessToken, 
    },
  });
}
export  const cancelUserToZoho =async(userEmail:any,company:any,userRegistrationDate:any,servicePlanId:any,firstname:any,lastname:any,street:any,city:any,phone:any,state:any,country:any,zip:any,dedecatedNumber:any,accessToken:any)=>{  
const url =  `${configData.APiUrl}/Zoho/CancelUsertoZoho?userEmail=${userEmail}&company=${company}&userRegistrationDate=${userRegistrationDate}&servicePlanId=${servicePlanId}&firstname=${firstname}&lastname=${lastname}&street=${street}&city=${city}&phone=${phone}&state=${state}&country=${country}&zip=${zip}&dedecatedNumber=${dedecatedNumber}`;
  return await fetch  (url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:"Bearer " + accessToken, 
    },
  });
}

export  const sendNewMsgZoho =async(userEmail:any)=>{
  
  const url =  `${configData.APiUrl}/Zoho/SendNewMsgZoho?userEmail=${userEmail}`;
  return await fetch  (url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:"Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi, 
    },
  });
}
export  const checkTrailUser =async(userEmail:any)=>{
  
  const url = `${configData.APiUrl}/Users/CheckTrialUsed?userEmail=${userEmail}`;
  return await fetch  (url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:"Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,      
    },
  });
}

//api Version2
export const getCompanyVersion_V2 = (token: string) => {
  debugger;
  const url = `${configData.APiUrl}/v2/Settings/GetCompanyVersion`;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      Authorization: "Bearer " + token,
    }
  });
};
export const getUser_V2 = (token: string) => {
  const url = `${configData.APiUrl}/v2/Users/GetUser`;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      Authorization: "Bearer " + token,
    }
  });
};
export const GetPersonalInbox_V2 = (pageIndex: number,inboxId:any,archiveFlag:any) => {
 debugger;
  const url = `${configData.APiUrl}/v2/Rooms/GetRooms?inboxId=${inboxId}&archiveFlag=${archiveFlag}&PageIndex=${pageIndex}`;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      Authorization: "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    }
  });
};
export const CreateRoom_V2=async(data: any,inboxId:Number) => {
  debugger;
 // let dataReturned;
  let isGroup=store.getState().reduxReducer.loggedInUser.inboxes.$values.filter((item:any) => item.InboxId === inboxId)[0].InboxType;
  isGroup=isGroup==1?true:false;
const url = `${configData.APiUrl}/V2/Rooms/CreateRoom?inboxId=${inboxId}&isGroup=${isGroup}`;
return await fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:"Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
    body: JSON.stringify(data),
  });
}
export  const uploadImageToBlob_V2 =async(fileBlobDetails:any)=>{
  const url = await `${configData.APiUrl}/V3/Send/AddMessageMedia`;
  return await fetch  (url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:"Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,      
    },body:JSON.stringify(fileBlobDetails)
  });
}
export const queueMessageChannel_V2 = (data:any,roomId:any,text: any,inboxId: any,uploadedFileUrl:any,serviceproviderid:any,channelName:any) => {
  text=text.replace(/%2520/g,"£_Yak");
  text=decodeURI(text);
  text=text.replace(/£_Yak/g,"%2520");
  text= text.replace(/\n/g,"%0A");
  text=text.replace(/&/g,"%26");
  const version ="React"
  if(text?.length === 0){ text="%00"}
  debugger;
  let isGroup=store.getState().reduxReducer.loggedInUser.inboxes.$values.filter((item:any) => item.InboxId === inboxId)[0].InboxType;
  isGroup=isGroup==1?true:false;
  var SendRequest:any={participants:data,roomId:roomId,isGroup:isGroup,text:text,inboxId:inboxId,uploadedFiles:uploadedFileUrl,serviceProviderId:serviceproviderid,channelName:channelName,yakchatVersion:version};

  const url = `${configData.APiUrl}/V3/Send/QueueMessage`;
  return fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:"Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
    body: JSON.stringify(SendRequest),
  });
};
export  const searchConversation_V2 =async(archiveFlag: any, searchText: any, inboxId: any)=>{
  debugger;
  
  const url = await `${configData.APiUrl}/V2/Rooms/SearchRooms?inboxId=${inboxId}&archiveFlag=${archiveFlag}&searchText=${searchText}`;
  return await fetch  (url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:"Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,      
    },
  });
}
export const getPagingMessagesByRoomId_V2 = (roomId: any, pageIndex: any) => {
  const url = `${configData.APiUrl}/V2/Rooms/GetMessages?roomId=${roomId}&pageIndex=${pageIndex}`;
    return fetch(url, {
      method: "GET",
      headers: {
        accept: "application/json;",
        "Content-Type": "application/json",
        Authorization:"Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
      }
    });
};
export const unreadCounterUpdate_V2=(roomId:any,lastIncomingMessageId:any)=>{
  const url = `${configData.APiUrl}/V2/Rooms/UpdateMessageStatus?roomId=${roomId}&lastSeen=${lastIncomingMessageId}`;
  return fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:"Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
  });  
}
export const getyakchatcontactlist_V2 = (pageindex: any) => {
  
  const url = `${configData.APiUrl}/V2/Contacts/GetContacts?pageIndex=` + pageindex;
    return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:"Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
  });
};
export const getContactsByListId_V2 = (listId: any) => {
  const url =`${configData.APiUrl}/V2/ContactList/GetLocalContactsByListId?id=` +  listId;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:"Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
  });
};
export const saveYakChatContact_V2 = async(data: any, savedFrom: any) => {
  let url = '';
  debugger;
   var userEmail = store.getState().reduxReducer.loggedInUser.account.userName;
   data.userEmail=userEmail;
   data.sureName=data.lastName;
   data.foreName=data.firstName;
   data.ContactEmail=data.email;
   
   if( data.groupEmail !== ""&&data.groupEmail !== null ){
    data.InboxId=store.getState().reduxReducer.loggedInUser.inboxes.$values.filter((item:any) => item.InboxType !== 0&&item.Name== data.groupEmail)[0].InboxId}
    if( data.groupEmail == ""||data.groupEmail == null ){
      data.InboxId=store.getState().reduxReducer.loggedInUser.inboxes.$values.filter((item:any) => item.InboxType == 0)[0].InboxId}
    
    url =await `${configData.APiUrl}/V2/Contacts/AddContact`;
  // }else  if(data.email === "" && groupEmail === ""){
  //   url =await `${configData.APiUrl}/LocalContacts/InsertContacts?Email=""&Phone=${phoneNumber}&foreName=${data.firstName}&sureName=${data.lastName}&userEmail=${userEmail}&groupEmail=""`;
  // }else if(data.email === "" && groupEmail !== ""){
  //   url =await `${configData.APiUrl}/LocalContacts/InsertContacts?Email=""&Phone=${phoneNumber}&foreName=${data.firstName}&sureName=${data.lastName}&userEmail=${userEmail}&groupEmail=${groupEmail}`;
  // }
  // else if(groupEmail === "" && data.email !== "") {
  //   url =await `${configData.APiUrl}/LocalContacts/InsertContacts?Email=${data.email}&Phone=${phoneNumber}&foreName=${data.firstName}&sureName=${data.lastName}&userEmail=${userEmail}&groupEmail=""`;
  // } else if(data.lastName === "") {
  //   url =await `${configData.APiUrl}/LocalContacts/InsertContacts?Email=${data.email}&Phone=${phoneNumber}&foreName=${data.firstName}&sureName=""&userEmail=${userEmail}&groupEmail=${groupEmail}`;
  // } 
  // else{
  //   url  = await`${configData.APiUrl}/LocalContacts/InsertContacts?Email=${data.email}&Phone=${phoneNumber}&foreName=${data.firstName}&sureName=${data.lastName}&userEmail=${userEmail}&groupEmail=${groupEmail}`;
  // }
  return await fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization: "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },body: JSON.stringify(data)
  }); 
};
export const uploadYakChatContact_V2 = async(data: any, savedFrom: any) => {
  // var phoneNumber= encodeURIComponent(data.phoneNumber);
  const url  = await`${configData.APiUrl}/V2/Contacts/AddMultipleContacts`;
  return await fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization: "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },body: JSON.stringify(data)
  });  
};

export const editYakChatContact_V2 = (id: any, data: any) => {
  debugger;
  data.ContactId=id;
    let url = '';
    data.ContactEmail=data.userEmail;
     var userEmail = store.getState().reduxReducer.loggedInUser.account.userName;
     data.userEmail=userEmail;
     data.PhoneNumber=data.Phone;
     data.ContactEmail=data.Email;
     data.InboxId=store.getState().reduxReducer.sharedInboxData.currentSelected.email;
    if( data.groupEmail !== ""&&data.groupEmail !== null ){
      data.InboxId=store.getState().reduxReducer.loggedInUser.inboxes.$values.filter((item:any) => item.InboxType !== 0&&item.Name== data.groupEmail)[0].InboxId}
      if( data.groupEmail == ""||data.groupEmail == null ){
        data.InboxId=store.getState().reduxReducer.loggedInUser.inboxes.$values.filter((item:any) => item.InboxType == 0)[0].InboxId}
       url = `${configData.APiUrl}/V2/Contacts/UpdateContact`;
    
    return fetch(url, {
      method: "POST",
      headers: {
        accept: "application/json;",
        "Content-Type": "application/json",
        Authorization:"Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
      },body: JSON.stringify(data)
    });
  };
export const saveYakChatSettings_V2 = (checkedvalue: string,isSharePoint: string) => {
    const url = `${configData.APiUrl}/V2/Settings/UpdateSetting?userEmail=${store.getState().reduxReducer.loggedInUser.account.userName}&flag=${checkedvalue}&isSharePoint=${isSharePoint}`;
    return fetch(url, {
      method: "POST",
      headers: {    
        accept: "application/json;",
        "Content-Type": "application/json",
        Authorization: "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
      }
    });
  };
  export const searchyakchatcontactlist_V2 = (pageindex: any, searchFilter: any) => {
    searchFilter = encodeURIComponent(searchFilter);
    // var sharedEmails =  store.getState().reduxReducer.shareMsgCount?.$values !== undefined ? store.getState().reduxReducer.shareMsgCount?.$values.map((a: any) => a.sharedEmail).toString() : store.getState().reduxReducer.shareMsgCount?.map((a: any) => a.sharedEmail).toString();
    // var userEmail = encodeURIComponent( store.getState().reduxReducer.loggedInUser.account.userName);
    // userEmail= userEmail + "," + sharedEmails;
    const url =`${configData.APiUrl}/V2/Contacts/SearchContacts?pageIndex=` + pageindex +`&searchText=` +searchFilter;
    return fetch(url, {
      method: "GET",
      headers: {
        accept: "application/json;",
        "Content-Type": "application/json",
         Authorization:"Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi
           // "Access-Control-Allow-Origin": "*",
        // "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Authorization",
        // "Access-Control-Request-Method": "GET, POST, DELETE, PUT, OPTIONS",
        // mode: "no-cors",
      },
    });
  };
  export const GetContactLists_V2 = (email:any) => {
    const url = `${configData.APiUrl}/V2/ContactList/GetContactLists`;
    return fetch(url, {
      method: "GET",
      headers: {
        accept: "application/json;",
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
      }
    });
  };
  export const AddContactList_V2 = (participants: any) => {
    debugger;
    if( participants.GroupEmail == ""||participants.GroupEmail == null ){
      participants.InboxId=store.getState().reduxReducer.loggedInUser.inboxes.$values.filter((item:any) => item.InboxType == 0)[0].InboxId}
    const url = `${configData.APiUrl}/V2/ContactList/AddList`;
    return fetch(url, {
      method: "POST",
      headers: {
        accept: "application/json;",
        "Content-Type": "application/json",
        Authorization:"Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
      },
      body: JSON.stringify(participants),
    });
  };
  export const UpdateContactList_V2 = (contactLists: any) => {
    debugger;
    contactLists.InboxId=contactLists.GroupEmail;
    if( contactLists.GroupEmail == ""||contactLists.GroupEmail == null ){
      contactLists.InboxId=store.getState().reduxReducer.loggedInUser.inboxes.$values.filter((item:any) => item.InboxType == 0)[0].InboxId}

    const url = `${configData.APiUrl}/V2/ContactList/UpdateList`;
    return fetch(url, {
      method: "POST",
      headers: {
        accept: "application/json;",
        "Content-Type": "application/json",
        Authorization:"Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
      },
      body: JSON.stringify(contactLists),
    });
  };
  // export const removeContactListsLocalContact_V2 = ( contactId: number,listId: any,email: string) => {
  //   const url = `${configData.APiUrl}/V2/ContactList/RemoveContact?ContactIds=` + contactId + `&listId=` +listId;
  //   return fetch(url, {
  //     method: "Delete",
  //     headers: {
  //       accept: "application/json;",
  //       "Content-Type": "application/json",
  //       Authorization:
  //         "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
  //     }
  //   });
  // };
  export const DeleteThisList_V2 = (id: string) => {
    const url = `${configData.APiUrl}/V2/ContactList/DeleteList?listId=${id}`;
    return fetch(url, {
      method: "Delete",
      headers: {
        accept: "application/json;",
        "Content-Type": "application/json",
        Authorization:  "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
      }
    });
  };
  export const DeleteThisContact_V2 = (id: string, phoneNumber: any) => {
    const url = `${configData.APiUrl}/V2/Contacts/DeleteContact?contactId=${id}`;
    return fetch(url, {
      method: "Delete",
      headers: {
        accept: "application/json;",
        "Content-Type": "application/json",
        Authorization:"Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
      }
    });
  };
  export  const getSasTokenBlobs_V2 =async()=>{
    const url = await `${configData.APiUrl}/V2/Send/GetMediaToken`;
    return await fetch  (url, {
      method: "GET",
      headers: {
        accept: "application/json;",
        "Content-Type": "application/json",
        Authorization:"Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,      
      },
    });
  }
  export const CloseConversation_V2 = (roomId: any, flag: any) => {
    const url = `${configData.APiUrl}/V2/Rooms/CloseRoom?roomId=${roomId}&archiveFlag=${flag}`;
    return fetch(url, {
      method: "POST",
      headers: {
        accept: "application/json;",
        "Content-Type": "application/json",
        Authorization:"Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
      }
    });
  }
  export  const servicePhoneNumberUpdateTrail_V2 =async(PhoneNumber:any, trail:any,accessToken:any)=>{
    const url = await `${configData.APiUrl}/V2/SenderId/UpdateTrialPhoneNumber?phoneNumber=${PhoneNumber}&trial=${trail}`;
    return await fetch  (url, {
      method: "POST",
        headers: {
          accept: "application/json;",
          "Content-Type": "application/json",
          Authorization:"Bearer " + accessToken,      
        },
      });
    }  
    export const addSharedContactApi_V2 = (lstContact:any) => {
      const url  = `${configData.APiUrl}/V2/Contacts/AddMultipleContacts`;
      return  fetch(url, {
        method: "POST",
        headers: {
          accept: "application/json;",
          "Content-Type": "application/json",
          Authorization: "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
        },body: JSON.stringify(lstContact)
      });  
      // const url = `${configData.APiUrl}/LocalContacts/AddImportedContacts`;
      // return fetch(url, {
      //   method: "POST",
      //   headers: {
      //     accept: "application/json;",
      //     "Content-Type": "application/json",
      //     Authorization:
      //       "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
      //   },body:JSON.stringify(lstContact)
      // });
    };
    export  const registerUserTrail_V2 =async(accessToken:any,userTrial:any)=>{
      const url = await `${configData.APiUrl}/V2/Users/AddTrial`;
      return await fetch  (url, {
        method: "POST",
        headers: {
          accept: "application/json;",
          "Content-Type": "application/json",
          Authorization:"Bearer " + accessToken,      
        },body:JSON.stringify(userTrial)
      });
    }
    export const getOutlookcontactfoldersWithContacts = () => {
      const url = `${configData.graphApiUrl}/v1.0/me/contactFolders?$expand=contacts`;
      return fetch(url, {
        method: "GET",
        headers: {
          accept: "application/json;",
          "Content-Type": "application/json",
          Authorization: "Bearer " + store.getState().reduxReducer.loggedInUser.accessToken,
        },
      });
    };
    export async function TranslateText (text:any,from:any,to:any) {
      debugger;
      let dataReturned;
      let key = "6bf5158a8d4e4a03bd9544c43dcd1633";
      let endpoint = "";
      let location = "centralus";
      const url = `https://api.cognitive.microsofttranslator.com/translate?api-version=3.0&from=${from}&to=${to}`;
      let textToTranslate = [{'text':text}];
    await  fetch(url, {
        method: "POST",
        headers: {
          accept: "application/json;",
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key": key,
          "Ocp-Apim-Subscription-Region":location
        },
        body: JSON.stringify(textToTranslate),
      }) .then(async function(response) {                      // first then()
        if(response.ok)
        {
          dataReturned=response.json();       
          return dataReturned;  
        }
    else{return response.text().then(text => { throw new Error(text) })}
    })  
    .then(function(text) {                          // second then()
      console.log('Request successful', text); 
      dataReturned=text;       
          return dataReturned;  
    })  
    .catch(function(error) {   
      dataReturned=error;
        return dataReturned;
    });
    return dataReturned;
    };
    export async function DetectTranslateText (text:any) {
      debugger;
      let dataReturned;
      let key = "6bf5158a8d4e4a03bd9544c43dcd1633";
      let endpoint = "";
      let location = "centralus";
      const url = `https://api.cognitive.microsofttranslator.com/detect?api-version=3.0`;
      let textToTranslate = [{'text':text}];
    await  fetch(url, {
        method: "POST",
        headers: {
          accept: "application/json;",
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key": key,
          "Ocp-Apim-Subscription-Region":location
        },
        body: JSON.stringify(textToTranslate),
      }) .then(async function(response) {                      // first then()
        if(response.ok)
        {
          dataReturned=response.json();       
          return dataReturned;  
        }
    else{return response.text().then(text => { throw new Error(text) })}
    })  
    .then(function(text) {                          // second then()
      console.log('Request successful', text); 
      dataReturned=text;       
          return dataReturned;  
    })  
    .catch(function(error) {   
      dataReturned=error;
        return dataReturned;
    });
    return dataReturned;
    };
    export async function GetLanguages () {
      let dataReturned;
      let key = "6bf5158a8d4e4a03bd9544c43dcd1633";
      let endpoint = "";
      let location = "centralus";
      const url = `https://api.cognitive.microsofttranslator.com/languages?api-version=3.0&scope=translation`;
    
    await  fetch(url, {
        method: "Get",
        headers: {
          accept: "application/json;",
        }
      }) .then(async function(response) {                      // first then()
        if(response.ok)
        {
          dataReturned=response.json();       
          return dataReturned;  
        }
    else{return response.text().then(text => { throw new Error(text) })}
    })  
    .then(function(text) {                          // second then()
      console.log('Request successful', text); 
      dataReturned=text;       
          return dataReturned;  
    })  
    .catch(function(error) {   
      dataReturned=error;
        return dataReturned;
    });
    return dataReturned;
    };
    export async function SaveTransltion(roomId:any,language:any){
      debugger;
      let dataReturned;
      const url = `${configData.APiUrl}/V2/Rooms/UpdateRoomLanguage?roomId=${roomId}&language=${language}`;
      await fetch(url, {
        method: "PATCH",
        headers: {
          accept: "application/json;",
          "Content-Type": "application/json",
          Authorization:"Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
        }
      }).then(async function(response) {                      // first then()
          if(response.ok)
          {
            dataReturned=response.json();    
            return dataReturned;  
          }
      else{return response.text().then(text => { throw new Error(text) })}
      })  
      .then(function(text) {                          // second then()
        console.log('Request successful', text); 
        dataReturned=text;       
            return dataReturned;  
      })  
      .catch(function(error) {   
        dataReturned=error;
          return dataReturned;
      });
      return dataReturned;
    };
    
    
    