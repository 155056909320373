import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import {
  BrowserRouter,
  Route,
  HashRouter
} from "react-router-dom";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./TeamsApp/globalTheme.css";
import "./TeamsApp/antd-minified.css";
import configureStore from "./TeamsApp/redux/store/Store";
import MainApp from './TeamsApp/Login/main'
import CallTowerMainApp from './TeamsApp/Login/callTower/callTowerMain';
import RegisterCompany from "./TeamsApp/Registration/yakchatRegister/registration";
import RegisterUser from "./TeamsApp/Registration/yakchatRegister/registerUser";
import RegisterCompanyDetails from "./TeamsApp/Registration/yakchatRegister/registerCompanyDetails";
import RegisterTerms from "./TeamsApp/Registration/yakchatRegister/registerTerms";
import CommsMain from "./TeamsApp/Login/commsChoice/CommsMain";
import AdminConsent from "./AdminConsent/adminConsent";
import WelcomePage from "./AdminConsent/welcomePage";
import SystemUnavailable from "./TeamsApp/loginError/SystemUnavailable";
import CallTowerConsent from "./AdminConsent/callTowerConsent";
import CommsConsent from "./AdminConsent/commsConsent";
import WelcomeCallTower from "./AdminConsent/welcomeCallTower";
import WelcomeCommsChoice from "./AdminConsent/welcomeComms";
import closeAfterPermissions from "./AdminConsent/closeAfterPermissions";
import RegisterComms from "./TeamsApp/Registration/commsRegister/registerComms";
import RegisterCallTower from "./TeamsApp/Registration/callTowerRegister/callTowerRegister"
import Authentication from "./TeamsApp/loginError/authentication";
import Bandwidth from "./TeamsApp/Registration/yakchatRegister/banwidthNumber";
import OneNetMain from "./TeamsApp/Login/oneNet/oneNetMain";
import RegisterOneNet from "./TeamsApp/Registration/oneNetRegister/oneNetRegister";
import MobexMain from "./TeamsApp/Login/mobex/mobexMain";
import OneucMain from "./TeamsApp/Login/oneuc/oneucMain";
import RegisterMobex from "./TeamsApp/Registration/mobexRegister/mobexRegister";
import RegisterOneuc from "./TeamsApp/Registration/oneucRegister/oneucRegister";
import setup4Register from "./TeamsApp/Registration/setup4Register/setup4Register";
import setup4Main from "./TeamsApp/Login/setup4/setup4Main";
export const store = configureStore();
// var req = window.location.origin;
ReactDOM.render(
  <React.StrictMode>
    <HashRouter
      basename="/#"
    >
      <div className="App">
        <Provider store={store}>
          <Route exact path="/" component={MainApp} />
          <Route exact path="/calltower" component={CallTowerMainApp} />
          <Route exact path="/commschoice" component={CommsMain} />
          <Route exact path="/registerUser" component={RegisterCompany} />
          <Route path="/register" component={RegisterUser} />
          <Route exact path="/registerDetails" component={RegisterCompanyDetails} />
          <Route exact path="/registerTerms" component={RegisterTerms} />
          <Route exact path="/adminConsent" component={AdminConsent} />
          <Route exact path="/closeAfterPermissions" component={closeAfterPermissions} />
          <Route exact path="/Welcome" component={WelcomePage} />
          <Route exact path="/SystemUnavailable" component={SystemUnavailable} />
          <Route exact path="/callTowerConsent" component={CallTowerConsent} />
          <Route exact path="/commsChoiceConsent" component={CommsConsent} />
          <Route exact path="/welcomeCalltower" component={WelcomeCallTower} />
          <Route exact path="/welcomeCommsChoice" component={WelcomeCommsChoice} />
          <Route exact path="/registercomms" component={RegisterComms} />
          <Route exact path="/registercalltower" component={RegisterCallTower} />
          <Route exact path="/autherror" component={Authentication} />
          <Route exact path="/nobandwidthnumber" component={Bandwidth} />
          <Route exact path="/oneNet" component={OneNetMain} />
          <Route exact path="/oneNetRegister" component={RegisterOneNet} />
          <Route exact path="/mobex" component={MobexMain} />
          <Route exact path="/oneuc" component={OneucMain} />
          <Route exact path="/mobexRegister" component={RegisterMobex} />
          <Route exact path="/oneucRegister" component={RegisterOneuc} />
          <Route exact path="/setup4Register" component={setup4Register} />
          <Route exact path="/setup4" component={setup4Main} />
        </Provider>
      </div>
    </HashRouter>
    {/* <BrowserRouter>  
   <Provider store={store}>
   <Route  exact path= "/" component={MainApp} />
        <Route exact path="/calltower" component={CallTowerMainApp} />
        <Route exact path="/commschoice" component={CommsMain} />
        <Route exact path="/registerUser" component={RegisterCompany} />
        <Route exact path="/register" component={RegisterUser} />
        <Route exact path="/registerDetails" component={RegisterCompanyDetails} />
        <Route exact path="/registerTerms" component={RegisterTerms} />
        <Route exact path="/adminConsent" component={AdminConsent} />
        <Route exact path="/closeAfterPermissions" component={closeAfterPermissions}/>
        <Route exact path="/Welcome" component={WelcomePage}/>
   <Route exact path="/Thankyou" component={Thankyou}/>
     <Route exact path="/SystemUnavailable" component={SystemUnavailable} />
          <Route exact path="/callTowerConsent" component={CallTowerConsent} />
          <Route exact path="/commsChoiceConsent" component={CommsConsent} />
          <Route exact path="/welcomeCalltower" component={WelcomeCallTower} />
          <Route exact path="/welcomeCommsChoice" component={WelcomeCommsChoice} />
          <Route exact path="/registercomms" component={RegisterComms} />
          <Route exact path="/registercalltower" component={RegisterCallTower} />
          <Route exact path="/autherror" component={Authentication} />
          <Route exact path="/nobandwidthnumber" component={Bandwidth} />
          <Route exact path="/oneNet" component={OneNetMain} />
          <Route exact path="/oneNetRegister" component={RegisterOneNet} />
          <Route exact path="/mobex" component={MobexMain} />
          <Route exact path="/mobexRegister" component={RegisterMobex} />
   </Provider>  
   </BrowserRouter> */}
  </React.StrictMode>,
  document.getElementById("root")
);
