import React from "react";
import { connect, } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import sponser from "../../../TeamsApp/images/commsgroup-alt-logo 2.svg"
import logoMobex from "../../../TeamsApp/images/mobexlogo.svg";
import logoOneuc from "../../../TeamsApp/images/OneucDesktopLogo.svg";
import logoOnet from "../../../TeamsApp/images/OneNetLogo.svg";
import logo from "../../../TeamsApp/images/CT Text Logo-2.png";
import logoSetup from "../../../TeamsApp/images/setupLogo.svg";


class ErrorLogo extends React.Component<any, any> {
    render() {
        return (
            <>
                <div className="login_logo text-center" > {this.props.whiteLabelBrand === "calltower" || window.location.href.toLowerCase().includes("calltower") ?
                    <img src={logo} alt="logo" className="app_logo call-tower-logo" style={{ height: "auto", width: "300px", }} /> : this.props.whiteLabelBrand === "onenet" || window.location.href.toLowerCase().includes("onenet") ? 
                    <img src={logoOnet} className="app_logo call-tower-logo" style={{ height: "auto", width: "300px", }} /> : this.props.whiteLabelBrand === "mobex" || window.location.href.toLowerCase().includes("mobex") ?
                    <img src={logoMobex} alt="logo" className="app_logo call-tower-logo" style={{ height: "auto", width: "300px", }} />: this.props.whiteLabelBrand === "oneuc" || window.location.href.toLowerCase().includes("oneuc") ?
                    <img src={logoOneuc} alt="logo" className="app_logo call-tower-logo" style={{ height: "auto", width: "300px", }} />: this.props.whiteLabelBrand === "setup4" || window.location.href.toLowerCase().includes("setup4") ?
                    <img src={logoSetup} alt="logo" className="app_logo call-tower-logo" style={{ height: "auto", width: "300px", }} /> : this.props.whiteLabelBrand === "commschoice" || window.location.href.toLowerCase().includes("commschoice") ?
                    <img src={sponser} alt="#" style={{ height: "auto" }} /> : <img src={"images/splash_logo.svg"} alt="logo" />}
                </div>
            </>
        );
    }
}
const mapStateToProps = (props: any) => {
    return {
        registerTheme: props.reduxReducer.registerTheme,
    };
};
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, null, any>, props: any) => {
    return {

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ErrorLogo);