import React from "react";
import { connect, } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { Link } from "react-router-dom";
import * as actions from "../../redux/actions/actions";
import { ReactComponent as ErrorIcon } from "../../images//eroor.svg";
import Logo from "../logo";

class RegisterCompanyDetails extends React.Component<any, any> {
  state = {
    Subscription: {
      subscriptionName: "",
    },
    sharedInboxName: "",
    emailUser1: "",
    emailUser2: "",
    emailUser3: "",
    accessToken: "",
    personalInbox: "",
    sharedInbox: "",
    mfaInbox: "",
    showFields: false,
    disableButton: false,
    inboxNamePlans: false,
    email1Errors:"",
    email2Errors:"",
    email3Errors:"",
    registerTheme:"",
  };
  componentDidMount() {
    let Subscription;
    let emailUser1;
    let emailUser2;
    let emailUser3;
    let sharedInboxName;
    if (this.props.subscribeDetails !== undefined) {
      sharedInboxName = this.props.subscribeDetails.sharedInboxName;
      emailUser1 = this.props.subscribeDetails.emailUser1;
      emailUser2 = this.props.subscribeDetails.emailUser2;
      emailUser3 = this.props.subscribeDetails.emailUser3;
      Subscription = this.props.subscribeDetails.Subscription === 7 ? "SharedInbox" : this.props.subscribeDetails.Subscription === 1 ? "PersonalInbox" : this.props.subscribeDetails.Subscription === 6 ? "MFA Inbox" : 0;
    }
    this.setState({ sharedInboxName: sharedInboxName, emailUser1: emailUser1, emailUser2: emailUser2, emailUser3: emailUser3, })
  }
  validEmail2 = (e: any) => {
    var emailChecker = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var emailtest = e.target.value;
    var validateEmail = emailChecker.test(emailtest);
    this.setState({ emailUser2: e.target.value })
    if (!validateEmail && e.target.value !== "") {
      this.setState({ email2Errors: true,
        disableButton: false, });
    } else {
      this.setState({ email2Errors: false,disableButton: true, });
    }
  };
  validEmail1= (e: any) => {
    var emailChecker = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var emailtest = e.target.value;
    var validateEmail = emailChecker.test(emailtest);
    this.setState({ emailUser1: e.target.value })
    if (!validateEmail && e.target.value !== "") {
      this.setState({ email1Errors: true,  disableButton: false, });
    } else {
      this.setState({ email1Errors: false,disableButton: true, });
    }
  };
  validEmail3 = (e: any) => {
    debugger
    var emailChecker = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var emailtest = e.target.value;
    var validateEmail = emailChecker.test(emailtest);
    this.setState({ emailUser3: e.target.value })
    if (!validateEmail && e.target.value !== "") {
      this.setState({ email3Errors: true ,disableButton: false, });
    } else {
      this.setState({ email3Errors: false,disableButton: true, });
    }
  };
  handelSharedInboxDetails = () => {
    var subscriptionDetailsObj = {
      Subscription: this.state.personalInbox !== "" ? 1 : this.state.sharedInbox !== "" ? 7
        : this.state.mfaInbox !== "" ? 6 : 0,
      sharedInboxName: this.state.sharedInboxName,
      emailUser1: this.state.emailUser1,
      emailUser2: this.state.emailUser2,
      emailUser3: this.state.emailUser3
    }
    this.props.setSubscriptionDetails(subscriptionDetailsObj, this.state.accessToken);
  }
  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    if (nextProps.accessToken !== prevState.accessTokenApi || 
      nextProps.registerTheme !== prevState.registerTheme)
      return {
        accessTokenApi: nextProps.accessTokenApi,
        registerTheme: nextProps.registerTheme,
      }
    else return null;
  }
  render() {

    return (
      <div className={this.state.registerTheme === "calltower" ? "userlog_sec calltowerSection_register" : this.props.registerTheme === "commsChoice" ? "userlog_sec commsSection_register" : this.props.registerTheme === "onenet" ? "userlog_sec OneNet_register" : this.props.registerTheme === "mobex" ? "userlog_sec OneNet_register Mobex_register" : this.props.registerTheme === "oneuc" ? "userlog_sec OneNet_register Oneuc_register" :  this.props.registerTheme === "setup4" ? "userlog_sec setupRegister" : "userlog_sec register_section"} >
        <div className="container">
          <div className="login_box">
          <Logo/>
            <p className="comp_reg text-center">Please complete your details to <br />register for YakChat</p>
            <div className="step_box text-center">
              <a href="#/register" className="step_btn ">Step 1</a>
              <a href="javascript:;void(0);" className="step_btn ">Step 2</a>
              <a href="javascript:;void(0);" className="step_btn step_active">Step 3</a>
              <a href="javascript:;void(0);" className="step_btn">Step 4</a>
            </div>
            <div className="user_detail">
              <div className="register_scroll">
                <h3 className="user_head">Choose Your SMS Inbox</h3>
                <form>
                  <div className="mb-3">
                    <label className="term_check">
                      Personal Inbox (for your eyes only)
                      <input type="radio" value="PersonalInbox" id="personalradiobtn" name="radio" onChange={(e) =>
                        this.setState({
                          personalInbox: e.currentTarget.value,
                          sharedInbox: "",
                          mfaInbox: "",
                          showFields: false,
                          disableButton: true,
                          inboxNamePlans:false,
                        })
                      } />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="mb-3">
                    <label className="term_check">
                      Shared Inbox (for your entire team & Microsoft Teams channel)
                      <input type="radio" value="SharedInbox" name="radio"
                        onChange={(e) =>
                          this.setState({
                            sharedInbox: e.currentTarget.value,
                            personalInbox: "",
                            mfaInbox: "",
                            showFields: true,
                            disableButton: true,
                            inboxNamePlans: true,
                          })}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="mb-3">
                    <label className="term_check">
                      MFA Inbox (A Shared Inbox that receives multi-factor authentication codes)
                      <input type="radio" value=" MFA Inbox"
                        onChange={(e) =>
                          this.setState({
                            mfaInbox: e.currentTarget.value,
                            sharedInbox: "",
                            personalInbox: "",
                            showFields: true,
                            disableButton: true,
                            inboxNamePlans: true,
                          })} name="radio" />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  {this.state.showFields ?
                    <>
                      <div className="mb-3">
                        <label className="form-label">SHARED INBOX NAME</label>
                        <input type="text" className="form-control"
                          value={this.state.sharedInboxName}
                          onChange={(e) => { this.setState({ sharedInboxName: e.target.value }) }}
                          placeholder="Shared Inbox Name" /> 
                      </div>

                      <div className="mb-3">
                        <label className="form-label">ADDITIONAL USERS (TO SHARE THE SAME INBOX)</label>
                        <input type="email" className="form-control" value={this.state.emailUser1}
                          onChange={this.validEmail1} placeholder="Enter user's Microsoft 365 E-mail address" />
                      {this.state.email1Errors ? (
                      <span className="error-div">
                        <ErrorIcon className="error-icon" />{" "}
                        <span className="error-span">
                          Please Enter a Valid Email
                        </span>
                      </span>
                    ) : ("")}
                      </div>
                      <div className="mb-3">
                        <input type="email" className="form-control"
                          value={this.state.emailUser2}
                          onChange={this.validEmail2 }
                          placeholder="Enter user's Microsoft 365 E-mail address" />
                            {this.state.email2Errors ? (
                      <span className="error-div">
                        <ErrorIcon className="error-icon" />{" "}
                        <span className="error-span">
                          Please Enter a Valid Email
                        </span>
                      </span>
                    ) : ("")}
                      </div>
                      <div className="mb-3">
                        <input type="email" className="form-control"
                          value={this.state.emailUser3}
                          onChange={this.validEmail3}
                          placeholder="Enter user's Microsoft 365 E-mail address" />
                           {this.state.email3Errors ? (
                      <span className="error-div">
                        <ErrorIcon className="error-icon" />{" "}
                        <span className="error-span">
                          Please Enter a Valid Email
                        </span>
                      </span>
                    ) : ("")}
                      </div>
                    </> : ""}
                </form>
              </div>
              <div className="next_backbtn">
                <Link to="/registerUser">
                  <button className="nxtBtn back_btn">Back</button>
                </Link>
                {
                  (this.state.disableButton ? this.state.inboxNamePlans ? 
                    this.state.sharedInboxName ? <Link to="/registerTerms"> <button onClick={this.handelSharedInboxDetails} className="nxtBtn registerNext" >Next</button> </Link>
                     : <button className={"disable_btn_nxt registerNext"} >Next</button>
                     : <Link to="/registerTerms">
                      <button onClick={this.handelSharedInboxDetails} className="nxtBtn registerNext" >Next</button>
                    </Link> : <button className={"disable_btn_nxt registerNext"} >Next</button>)

                /* <Link to="/registerTerms">
                  <button onClick={this.handelSharedInboxDetails} className="nxtBtn" >Next</button>
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (props: any) => {
  return {
    accessToken: props.reduxReducer.accessTokenApi,
    companyDetails_registeration: props.reduxReducer.companyDetails_registeration,
    registerTheme: props.reduxReducer.registerTheme,
    subscribeDetails: props.reduxReducer.subscriptionDetails_registeration,
  };
};
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, null, any>, props: any) => {
  return {
    setSubscriptionDetails: (subscription: any, accessToken: any) => {
      dispatch<any>(actions.setSubscriptionDetails(subscription, accessToken));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(RegisterCompanyDetails);