import React from "react";
import "../TeamsApp/globalTheme.css";
import AuthHelper from "../Config/msalConfig";
import { setTimeout } from "timers";
import Login from "../TeamsApp/Login/login";
import configData from "../appsettings.json"
export default class AdminConsent extends React.Component<any, any>  {
  state={
    showLink:false,
    url:"",
    showLogin:false,
    isModalVisible: true,
  }
  componentDidMount() {
    // window.location.origin
    localStorage.clear();
    sessionStorage.clear();
  }
  showModal = () => {
    setTimeout(() => {
      this.setState({ isModalVisible: true })
    }, 500);

  };
  checkLoginHandel =()=>{
    localStorage.clear();
    sessionStorage.clear();
    window.open("https://login.microsoftonline.com/common/adminConsent?client_id="+configData.client_id+"&redirect_uri="+configData.BaseUrlTeams+"/thankyou.html?brand=yakChat");
      this.setState({showLogin:true})
      setTimeout(() => {
        this.showModal();
      }, 200);
      setTimeout(() => {
        window.location.assign(configData.publishurl+"/#/adminConsent")
        localStorage.clear();
        sessionStorage.clear();
      }, 1000);
     
  }
  handelRefreshContinue = () => {
    this.setState({ isModalVisible: true })
    setTimeout(() => {
      if (window.location.href.toLowerCase().includes('calltower') === true) {
        window.location.assign(configData.publishurl+"/#/calltower")
      }else if(window.location.href.toLowerCase().includes('commschoice') === true){
        window.location.assign(configData.publishurl+"/#/commschoice")
    }
        else if(window.location.href.toLowerCase().includes('onenet') === true){
            window.location.assign(configData.publishurl+"/#/oneNet")
    }   else if(window.location.href.toLowerCase().includes('mobex') === true){
        window.location.assign(configData.publishurl+"/#/mobex")
}
else if(window.location.href.toLowerCase().includes('oneuc') === true){
  window.location.assign(configData.publishurl+"/#/oneuc")
}
else if(window.location.href.toLowerCase().includes('setup4') === true){
 
    window.location.assign(configData.publishurl+"/#/setup4")
}else{
        window.location.assign(configData.publishurl+"/#/")
    } }, 100);
  }
  handelRefresh =()=>{
    localStorage.clear();
    sessionStorage.clear();
    setTimeout(() => {
      localStorage.clear();
      sessionStorage.clear();
      AuthHelper.initSignin();
    }, 50);
    setTimeout(() => {
      window.location.assign(configData.publishurl+"/#/");
    }, 100);
   
  }
  render() {
    return (
      <>
      { this.state.showLogin ?  <>
            <div className= { this.state.isModalVisible ? "refreshPopup" : "hidePopup"} >
              <div className={ this.state.isModalVisible ? "sure_detail  " : "hidePopup"} style={{ backgroundColor: "#fff" }}>
                <div className="addcont_txt"  >
                  <h3>
                    Connect to Microsoft Teams
                  </h3>
                  <p className="save_press">
                    <span style={{ color: "#999999" }}>Refresh the YakChat application after admin consent has been provided.</span>
                  </p>
                  <button className="btn  cstm_btn consent_color"
                    style={{ backgroundColor: "#fcbf00", color: "#fff" }}
                    onClick={this.handelRefreshContinue}
                  >
                   Refresh
                  </button>
                </div>
              </div>
              </div>
              <Login loginStatus={this.props.loggedInUser}></Login>
            </>  :
      <section className="consent_container">
           <div className="consent_popup ">
          <div className="addcontact_box"   style={{backgroundColor:"#fcbf00"}}>
            <div className="sure_detail" style={{backgroundColor:"#fff"}}>
              <div className="addcont_txt"  >
                <h3>
                Connect to Microsoft Teams
                </h3>
                <p className="save_press">
                <span style={{color:"#999999"}}>Yakchat requires administrator consent to connect with your Microsoft 365 system.</span>
                </p>
              </div>
              <div className="addgr_btnbox" >
                <button
                onClick={this.handelRefresh}
                  className="btn cstm_btn blk_btn comms_color_black" style={{backgroundColor:"#000", color:"#fff"}}
                >
                  {" "}
                  Refresh{" "}
                </button>
                <button
                  className="btn  cstm_btn consent_color"
                  style={{backgroundColor:"#fcbf00", color:"#fff"}}
                  onClick={this.checkLoginHandel}
                >
                Continue
                </button>
              {this.state.showLink ? <a target="_blank" href={this.state.url}></a> :""}
              </div>
            </div>
          </div>
        </div>
      </section> }
      </>
    );
  }
}