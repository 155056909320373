import React, { ReactElement } from "react";
import moment from "moment";
import "./chatPanel.css";
import { ReactComponent as Pending } from "../../../images/wht_dots.svg";
import { store } from "../../../..";
import * as contactservice from "../../../services/Contacts.Service";
import { PromptState } from "msal/lib-commonjs/utils/Constants";
import * as action from "../../../redux/actions/actions";
import { connect, useDispatch } from "react-redux";
import { Dispatch } from "redux";
interface Props {
  status: number;
  errorMessage?: string;
  timeStamp: string | number | Date;
  textMsg?:string;
  direction:number;
}


 
 async function translateText ( text:any,direction:number,e:any)  {
  var from="";
    var to="";
  let companyVersion=store.getState().reduxReducer.companyVersion;
  let transEnabled=companyVersion==2?store.getState().reduxReducer.loggedInUser.user.EnableTranslation:store.getState().reduxReducer.loggedInUser.Subscription.EnableTranslation;
  if(transEnabled)
  {
  let resdetect:any= await contactservice.DetectTranslateText(text);
  if(resdetect[0]!==undefined)
  {
     from=resdetect[0].language;
     to= navigator.language;
   
  let res:any= await contactservice.TranslateText(text,from,to);
  if(res[0]!==undefined)
  {
    let lang=store.getState().reduxReducer.TranslationLanguages;
    let Listuserlang:any=[];
    if(lang!=undefined)
    {
  let translatedData=res[0].translations[0].text;
  Listuserlang = lang.filter((x: any)  => x.value==from);
  let languagefrom:string="";
  if(Listuserlang.length>0)
  {languagefrom=Listuserlang[0].label}
  store.dispatch<any>(action.setAlertData({ text:translatedData+"\n (Language :"+languagefrom+")",}) );//(translatedData+"\n (Language :"+languagefrom+")");
 let TranslationLanguage="";
 if(store.getState().reduxReducer.UserTransLang)
 {
   TranslationLanguage=store.getState().reduxReducer.UserTransLang;
 }
 let roomId=0;
  if(store.getState().reduxReducer.selectedroom!=undefined)
 {
   roomId=store.getState().reduxReducer.selectedroom;
   store.dispatch<any>(action.SaveTransltion(roomId, from,false));
   store.dispatch<any>(action.UserTransLang(from));
 }

    }
  }
  else{
   let errorMsg= JSON.parse(res.message).error.message;
   return "nullerror&£_"+errorMsg;
  }
}
}
  }

const Tick = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group 1184">
        <g id="Icons / Check">
          <g id="Group 1107">
            <path
              id="Path 505"
              d="M12.6261 5.08297L12.3581 4.80697C12.3107 4.75761 12.2539 4.71832 12.191 4.69149C12.1281 4.66465 12.0604 4.65082 11.9921 4.65082C11.9237 4.65082 11.856 4.66465 11.7931 4.69149C11.7302 4.71832 11.6734 4.75761 11.6261 4.80697L6.31706 10.275L4.09506 7.98697C4.04781 7.93748 3.99101 7.89808 3.9281 7.87117C3.86519 7.84425 3.79749 7.83037 3.72906 7.83037C3.66064 7.83037 3.59292 7.84425 3.53001 7.87117C3.46711 7.89808 3.4103 7.93748 3.36305 7.98697L3.09506 8.26297C2.99765 8.36431 2.94324 8.49941 2.94324 8.63997C2.94324 8.78053 2.99765 8.91564 3.09506 9.01697L5.94705 11.954C5.99521 12.0033 6.05276 12.0425 6.1163 12.0693C6.17985 12.0961 6.24811 12.1099 6.31706 12.1099C6.38602 12.1099 6.45426 12.0961 6.51781 12.0693C6.58135 12.0425 6.6389 12.0033 6.68706 11.954L12.6261 5.83697C12.7235 5.73564 12.7779 5.60053 12.7779 5.45997C12.7779 5.31941 12.7235 5.18431 12.6261 5.08297V5.08297Z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
const Tick2 = () => {
  return (
    <svg
      width="26"
      height="16"
      viewBox="0 0 26 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.626 4.848L12.358 4.572C12.3107 4.52264 12.2539 4.48335 12.191 4.45652C12.1281 4.42968 12.0604 4.41585 11.992 4.41585C11.9236 4.41585 11.8559 4.42968 11.793 4.45652C11.7301 4.48335 11.6733 4.52264 11.626 4.572L6.317 10.04L4.095 7.752C4.04775 7.70251 3.99095 7.66311 3.92804 7.6362C3.86513 7.60928 3.79743 7.5954 3.729 7.5954C3.66058 7.5954 3.59286 7.60928 3.52995 7.6362C3.46704 7.66311 3.41024 7.70251 3.36299 7.752L3.095 8.028C2.99759 8.12934 2.94318 8.26444 2.94318 8.405C2.94318 8.54556 2.99759 8.68067 3.095 8.782L5.94699 11.719C5.99515 11.7683 6.0527 11.8076 6.11624 11.8343C6.17978 11.8611 6.24805 11.8749 6.317 11.8749C6.38595 11.8749 6.4542 11.8611 6.51775 11.8343C6.58129 11.8076 6.63884 11.7683 6.687 11.719L12.626 5.602C12.7234 5.50067 12.7778 5.36556 12.7778 5.225C12.7778 5.08444 12.7234 4.94934 12.626 4.848V4.848Z" />
      <path d="M22.626 4.848L22.358 4.572C22.3107 4.52264 22.2539 4.48335 22.191 4.45652C22.1281 4.42968 22.0604 4.41585 21.992 4.41585C21.9236 4.41585 21.8559 4.42968 21.793 4.45652C21.7301 4.48335 21.6733 4.52264 21.626 4.572L16.317 10.04L14.095 7.752C14.0478 7.70251 13.9909 7.66311 13.928 7.6362C13.8651 7.60928 13.7974 7.5954 13.729 7.5954C13.6606 7.5954 13.5929 7.60928 13.53 7.6362C13.467 7.66311 13.4102 7.70251 13.363 7.752L13.095 8.028C12.9976 8.12934 12.9432 8.26444 12.9432 8.405C12.9432 8.54556 12.9976 8.68067 13.095 8.782L15.947 11.719C15.9952 11.7683 16.0527 11.8076 16.1162 11.8343C16.1798 11.8611 16.248 11.8749 16.317 11.8749C16.386 11.8749 16.4542 11.8611 16.5177 11.8343C16.5813 11.8076 16.6388 11.7683 16.687 11.719L22.626 5.602C22.7234 5.50067 22.7778 5.36556 22.7778 5.225C22.7778 5.08444 22.7234 4.94934 22.626 4.848V4.848Z" />
    </svg>
  );
};

const Alert = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.383 13.605H2.61197C2.35344 13.6102 2.09819 13.5465 1.87238 13.4205C1.64657 13.2945 1.45834 13.1107 1.32697 12.888C1.1998 12.674 1.13784 12.4276 1.14867 12.1789C1.15951 11.9302 1.24267 11.6901 1.38797 11.488L7.27397 3.04C7.41473 2.84761 7.59888 2.69112 7.81147 2.58325C8.02405 2.47538 8.25908 2.41916 8.49747 2.41916C8.73586 2.41916 8.97089 2.47538 9.18347 2.58325C9.39606 2.69112 9.58021 2.84761 9.72097 3.04L15.606 11.488C15.7514 11.6901 15.8346 11.9302 15.8455 12.1789C15.8563 12.4276 15.7943 12.6741 15.667 12.888C15.5357 13.1106 15.3476 13.2943 15.122 13.4203C14.8964 13.5462 14.6413 13.61 14.383 13.605V13.605ZM8.62797 10.666C8.41141 10.6566 8.1995 10.7307 8.03597 10.873C7.9629 10.9386 7.90487 11.0192 7.86586 11.1093C7.82685 11.1994 7.80779 11.2968 7.80997 11.395C7.80769 11.4927 7.82672 11.5898 7.86575 11.6794C7.90478 11.769 7.96287 11.8491 8.03597 11.914C8.20085 12.0532 8.41243 12.1247 8.62797 12.114C8.84611 12.126 9.06063 12.0545 9.22797 11.914C9.29924 11.8472 9.35605 11.7665 9.39488 11.6769C9.43372 11.5873 9.45375 11.4907 9.45375 11.393C9.45375 11.2953 9.43372 11.1987 9.39488 11.1091C9.35605 11.0195 9.29924 10.9388 9.22797 10.872C9.06153 10.7294 8.84691 10.6557 8.62797 10.666V10.666ZM7.72797 5.11L7.90097 9.91H9.23697L9.40997 5.11H7.72797Z"
        fill="#B00020"
      />
    </svg>
  );
};
const Status = (props: Props) => {
  const showMessageDelivery = () => {
    let span: ReactElement<any, any>;
    let { status, errorMessage,textMsg } = props; //message.ServiceMessages?.$values[0]?.Status;
   
    if(status !== undefined ) {
      if(status === 10){
        span = (
          <span data-tooltip="Waiting Confirmation">
        <Pending style={{width:17}}/>
        </span>
      );
      }else if(status === 20) {
      span = (
        <span data-tooltip="Sent">
          <Tick />
        </span>
      );
    } else if (status === 30) {
      span = (
        <span data-tooltip="Received">
          <Tick2 />
        </span>
      );
    } else {
      span = (
        <span
          data-tooltip={errorMessage} //message.ServiceMessages?.$values[0]?.ErrorMessage
        >
          <Alert />
        </span>
      );
    }
    return span;
  };  }

  return (
   
    <div className="bubble_time ">
       {store.getState().reduxReducer.TranslationLanguages!=undefined?
      <a href="javascript:void(0);" title="" onClick={x=>translateText(props.textMsg,props.direction,x)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          viewBox="0 0 25 25"
        >
          <g>
            <g>
              <path
                d="M17.814 11.317a25.334 25.334 0 0 0-.473-4.086c1.156-.326 2.19-.766 3.047-1.301 1.242 1.55 2 3.408 2.196 5.387zm-.981 8.134c.773.22 1.474.497 2.072.821a10.197 
                    10.197 0 0 1-3.176 1.795c.422-.745.794-1.627 1.104-2.616zm-6.486.79a12.767 12.767 0 0 1-.463-1.211 17.956 17.956 0 0 1 4.923 0c-.14.429-.294.833-.461 1.21-.781 1.757-1.624 
                    2.394-2 2.394-.376 0-1.22-.637-2-2.394zm-2.488-.79c.31.989.682 1.871 1.104 2.616a10.198 10.198 0 0 1-3.177-1.795 10.31 10.31 0 0 1 2.073-.82zm0-14.211a10.262 10.262 0 0 1-2.073-.82 
                    10.211 10.211 0 0 1 3.177-1.795c-.422.745-.794 1.627-1.104 2.615zm7.895 8.135a23.556 23.556 0 0 1-.427 3.653 19.696 19.696 0 0 0-2.981-.224c-1.029 0-2.03.077-2.981.224a23.653 23.653 0 0 
                    1-.428-3.653zm-6.817-2.058c.052-1.271.198-2.508.428-3.653.951.146 1.952.223 2.98.223 1.03 0 2.03-.077 2.982-.223.23 1.145.375 2.382.427 3.653zm6.792-8.692c1.15.4 
                    2.224 1.004 3.176 1.794-.598.324-1.299.601-2.072.821-.31-.988-.682-1.87-1.104-2.615zm-1.383 1.827c.167.377.32.782.461 1.21-.788.11-1.615.168-2.461.168-.847 
                    0-1.674-.058-2.462-.168.14-.428.295-.833.463-1.21.78-1.756 1.623-2.394 1.999-2.394.376 0 1.219.638 2 2.394zM4.303 5.93c.857.535 1.892.975 3.048 1.301a25.247 25.247 0 0 0-.473 
                    4.086h-4.77c.196-1.979.953-3.838 2.195-5.387zm-2.195 7.445h4.77c.053 1.447.217 2.824.473 4.086-1.156.327-2.19.767-3.047 1.301-1.243-1.55-2-3.408-2.196-5.387zm18.28 
                    5.387c-.857-.535-1.891-.974-3.047-1.301.256-1.262.42-2.639.473-4.086h4.77a10.187 10.187 0 0 1-2.196 5.387zM12.346 0C5.527 0 0 5.527 0 12.346 0 19.164 5.527 24.69 12.346 24.69c6.818 0 12.345-5.527 
                    12.345-12.345C24.691 5.527 19.164 0 12.346 0z"
              />
            </g>
          </g>
        </svg>
      </a>:<span></span>
}
      <span>{moment(new Date(props.timeStamp)).format("hh:mma")}</span>
      {showMessageDelivery()}
    </div>
  );
};

export default Status;
