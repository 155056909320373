import React, { createRef, RefObject } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import Picker from "emoji-picker-react";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { Drawer } from "@material-ui/core";
import * as action from "../../../redux/actions/actions";
import * as commonMethod from "../../../commonMethods";
import { ReactComponent as DotsIcon } from "../../../images/wht_dots.svg";
import { ReactComponent as BackIcon } from "../../../images/left_arrow.svg";
import { ReactComponent as GlobeIcon } from "../../../images/globe.svg";
import { ReactComponent as PicIcon } from "../../../images/pic.svg";
import { ReactComponent as EmojiIcon } from "../../../images/emoji.svg";
import { ReactComponent as SendIcon } from "../../../images/send.svg";
import { ReactComponent as GrayAvtar } from "../../../images/gray-avtar.svg";
import { ReactComponent as CrossIcon } from "../../../images/cross.svg";
import { ReactComponent as MuteIcon } from "../../../images/mute_noti.svg";
import { ReactComponent as PinIcon } from "../../../images/pin_black.svg";
import Lightbox from 'react-image-lightbox';
import ImagePreview from "../imagePreview/imagePreview";
import { Guid } from "guid-typescript";
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
// import LazyLoad from 'react-lazyload';
import Feed from "./Feed";
import { store } from "../../../..";
import { Dispatch } from "redux";
import * as contactservice from "../../../services/Contacts.Service";
import Select from 'react-select';
//const { ChatFeed, Message } = require("react-chat-ui");
class ChatPanel extends React.Component<any, any> {
  state = {
    showinfo: false,
    is_typing: false,
    inputText: "",
    displayEmojiPicker: false,
    messages: this.props.currentMessageRoom?.Messages?.$values,
    classAdd: false,
    currentMessageRoom: {},
    currentPageIndexForRoomMessage: 0,
    nextPageIndexForRoomMessages: 0,
    currentSelectedDropDownValue: { email: "" },
    file: [],
    fileBlob: "",
    fileName: '',
    progressBar: 0,
    photoIndex: 0,
    isOpen: false,
    shouldShow: false,
    imageUrl: "",
    sasToken :"",
    isChatInput:"",
    feedHeight: 0,
    index:-1,
    translationSource:"",
  }
  
  chatFeeds: any;
  loadChat:any;
  scrollUp:React.RefObject<HTMLDivElement>;
  scrollPanel: React.RefObject<HTMLDivElement>
  scrollDown: React.RefObject<HTMLDivElement>
  constructor(props: any, state: any) {
    super(props);
    this.chatFeeds = React.createRef();
    this.scrollUp = React.createRef();
    this.scrollPanel = React.createRef()
    this.loadChat = React.createRef()
    this.scrollDown = React.createRef()
  }
  static getDerivedStateFromProps = (nextProps: any, prevState: any) => {

    if (nextProps.sasToken !== prevState.sasToken ||
       nextProps.currentMessageRoom !== prevState.currentMessageRoom || nextProps.currentPageIndexForRoomMessage !== prevState.currentPageIndexForRoomMessage || nextProps.nextPageIndexForRoomMessages !== prevState.nextPageIndexForRoomMessages || nextProps.currentSelectedDropDownValue !== prevState.currentSelectedDropDownValue
       || nextProps.isChatInput !== prevState.isChatInput) {
      return {
        currentPageIndexForRoomMessage: nextProps.currentPageIndexForRoomMessage,
        nextPageIndexForRoomMessages: nextProps.nextPageIndexForRoomMessages,
        messages: [],
        currentSelectedDropDownValue: nextProps.currentSelectedDropDownValue,
      
        sasToken:nextProps.sasToken,
        isChatInput:nextProps.isChatInput
      };
    } else {
      return null;
    }
  };


  openContactTab = (contact: any) => {
    this.props.setSelectedContacts(contact);
    document.getElementById("contacts_tab")?.click();
    this.props.setTabOpened("contactopen")
  }
  clickListener: any;
  getBase64(file: any, callback: any) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => callback(reader.result);
    reader.onerror = error => { };
  }
  handleTranslate =async (event: any) => {
    debugger;
   if(this.state.inputText.length > 0 )
   {
    var currentlang=store.getState().reduxReducer.UserTransLang;
    if(currentlang==null||currentlang==undefined)
    {
      currentlang="";
    }
    const datatranslated=await translateText(currentlang,this.state.inputText);
    if(datatranslated.startsWith("nullerror"))
    {
    let errorMsg=datatranslated.split("&£_")[1].toString();
      this.props.setAlertData({ className: "danger", text: errorMsg, })
    setTimeout(() => {
      this.props.setAlertData(null);
    }, 2000);
    }
    else
    {
     this.setState({inputText:datatranslated});
    }
   }
  }
  handleChange = (event: any) => {
    debugger;
    let idCardBase64 = '';
    var imgaeName = Guid.create();
    var fileType = event.target.files[0]?.type.split('/');
    var imageSize = Math.round(event.target.files[0]?.size / Math.pow(1024, 1));
    var fileName = imgaeName + '.' + fileType[1];
    var allowedType = fileType[1]?.split(',');
    if (allowedType != "gif" && allowedType != "jpg" && allowedType != "jpeg" && allowedType != "png" && allowedType != "pjpeg" && allowedType != "pjp" && allowedType != "jfif") {
      this.props.setAlertData({ className: "danger", text: "Please Choose Valid File!", });
      setTimeout(() => { this.props.setAlertData(null); }, 2000);

    }
    else {
      if (imageSize < 5000) {
        this.setState({ progressBar: 40 })
        var ArrayFiles:any=this.state.file;
        var fileData:any = {};
        let ff=event.target.files[0];
        this.getBase64(event.target.files[0], (result: any) => {
          idCardBase64 = result;
          var blob = result.split(',');
          fileData={ url: URL.createObjectURL(ff),name: fileName , blob: blob[1] };
          ArrayFiles.push(fileData);
          this.setState({ file:ArrayFiles });
          this.setState({ fileBlob: blob[1] })
          setTimeout(() => { this.setState({ progressBar: 100 }) }, 1000);
        });
      } else {
        this.props.setAlertData({ className: "danger", text: "Size must be less than 5mb", });
        setTimeout(() => { this.props.setAlertData(null); }, 2000);
      }
    }
  }

  clickAnywhere = (e: any) => {
    // Added by maksud  
    if (!e.composedPath().includes(document.getElementById("emojiPicker")) && !e.composedPath().includes(document.getElementById("emojiPickerButton"))){
      this.setState({ displayEmojiPicker: false });
    }
  };
  


  // createObserver =()=>{
  //   // const elem = document.querySelector(".scroolupDiv")
  //   let observer;
  //   
  //   let options ={
  //       root: this.chatFeeds.current, // Page as root
  //       rootMargin: '0px',
  //       threshold: 0,
  //   }
  //   let hasNext = this.state.currentPageIndexForRoomMessage !== this.state.nextPageIndexForRoomMessages
  //   observer = new IntersectionObserver( (entries:any,observer:any)=> {
  //     entries.forEach((entry:any) => {
  //       if(entry.isIntersecting === true   ){
  //         //console.log(this.state.feedHeight)
          
  //         if (hasNext && this.scrollUp.current) {
  //           var pageCount = this.state.currentPageIndexForRoomMessage + 1;
           
  //           this.props.getRoomByRoomId(this.props.currentSelectedDropDownValue.email,this.props.currentMessageRoom, pageCount);
  //           this.chatFeeds.current.style.height = `${this.chatFeeds.current.scrollHeight * 2}px`
  //           //this.chatFeeds.current.scrollBy(0, 50)
  //           // scroll = scroll && (scroll + 100) 
  //           //this.props.currentMessageRoom
           
  //         }else {
  //           this.scrollUp.current!.style.height = '20px'
  //         }
            
  //       }  
          
  //    });
  //   }, options);
  //   observer.observe(this.scrollUp.current!)
  // }

  componentDidMount () {
     //this.createObserver();

    //make an initial data fetch
   

    if(this.props.isChatInput === true){
      this.setState({ inputText: "" })
    }
    this.clickListener = window.addEventListener("click", this.clickAnywhere);
    
  }
   componentWillUnmount() {
    window.removeEventListener("click", this.clickAnywhere);
    window.removeEventListener('scroll', this.onScroll);
  }


  componentDidUpdate(prevState:any,prevProps:any) {

    //scroll to bottom of page  
    // if(this.props.currentMessageRoom && this.props.currentMessageRoom.id) {
    //   this.scrollDown.current!.scrollIntoView({behavior: 'smooth'})
    // }
    //this.createObserver();
   
   
    

    //this.chatFeeds.current.onscroll = () => this.chatFeeds.current.scrollTop= this.chatFeeds.current.scrollHeight + 50
    // else {
    //   var data = document.getElementById("DIV_CHAT");
    //   console.log("this.state.currentPageIndexForRoomMessage ",this.state.currentPageIndexForRoomMessage )
    //   // if (this.state.currentPageIndexForRoomMessage === 2) {
    //     // const elem = document.getElementById("chat-panel");
    //     // elem?.scrollIntoView({ block: 'end' });
    //   // }
    //   data?.scrollTo({ top: 2000 });
    // }
  }
  onScroll = (e:any) => {
    //const scrollTop = this.chatFeeds.current.scrollTop;
    e.preventDefault();
    // if (scrollTop === 0) {
    //   if (this.state.currentPageIndexForRoomMessage !== this.state.nextPageIndexForRoomMessages) {
    //     var pageCount = this.state.currentPageIndexForRoomMessage + 1;
    //     console.log("", pageCount)  
    //     this.props.getRoomByRoomId(this.props.currentSelectedDropDownValue.email,this.props.currentMessageRoom, pageCount);
    //     //this.props.currentMessageRoom
    //   }
    // }
  };
  
  SaveTransltion=(e:any)=>{
    debugger;
    e.preventDefault();
    let TranslationLanguage="";
    if(store.getState().reduxReducer.UserTransLang)
    {
      TranslationLanguage=store.getState().reduxReducer.UserTransLang;
    }
    let roomId=0;
     if(store.getState().reduxReducer.selectedroom!=undefined)
    {
      roomId=store.getState().reduxReducer.selectedroom;
    this.props.SaveTransltion(roomId,TranslationLanguage);
    }
	
  }
  handleTranslationChange = (selectedItems: any) => {
    debugger;
    if(selectedItems.value!=undefined)
    {
     
     this.props.changelang(selectedItems.value);}
   
  }
  handelSpecialKey = (e: any) => {
    var specialKeys = e.keyCode;
    // if (e.key === "Enter" && e.shiftKey) {
    //   this.setState({ inputText: e.target.value + "\n" });
    //   
    // }
    // console.log("special",e.keyCode)
    var inputTextValue = e.target.value;
    if (specialKeys === 55) {
      inputTextValue.replace(/%20/g, "&");
      this.setState({ inputText: inputTextValue });
    }
  };
  handelTextChange = (e: any) => {
    if(this.props.isChatInput === true){
      this.setState({inputText:""})
    }

    if (e.key === "Enter" && e.shiftKey) {
      this.setState({ inputText: e.target.value + "\n" });
    } else {
      this.setState({ inputText: e.target.value });
    }
  };

  sendMessage = async (e: any) => {  
    const imageElement=(document.getElementById('inputImage')as HTMLInputElement);
    if(imageElement !== null){
      imageElement.value = "";
    }
    if(this.props.MFAUSER !== true) {
    if (e.key === "Enter") {
      let inputTextSpecialCharFixed = this.state.inputText;
      if (e.shiftKey) {
        this.setState({ classAdd: true });
        return
      }
      else if (e.preventDefault) e.preventDefault();
      this.setState({ classAdd: false });
      if (!this.props.currentMessageRoom) { return; }
      var roomId = this.props.currentMessageRoom.Id;
      const text = inputTextSpecialCharFixed;
      if (text == "" && this.state.fileBlob === "") {
        this.props.setAlertData({ className: "danger", text: "Message can not be empty!", });
        return;
      }
      if (roomId === undefined) {
        this.props.setAlertData({ className: "danger", text: "No Room is Available!" });
        setTimeout(() => { this.props.setAlertData(null); }, 2000);
        return;
      }
      const newMessageObj = { Id: 0, Direction: 10, Text: text, TimeStamp: new Date().toISOString(), status: 10 };
      this.setState({ messages: [...this.props.currentMessageRoom.Messages.$values, newMessageObj,], inputText: "" }, () => {
        const elem = document.getElementById("chat-panel");
        elem?.scrollIntoView(false);
      });
      debugger;
      let companyVersion=store.getState().reduxReducer.companyVersion;
      if(companyVersion===2)
      {
        var userEmail = this.state.currentSelectedDropDownValue !== undefined ? this.state.currentSelectedDropDownValue.email === "Closed" ? this.props.currentMessageRoom.CreatorEmail : this.state.currentSelectedDropDownValue.email : "";
        var value: any = [];
        var object: any = {};
        this.props.currentMessageRoom.Participants.forEach((element: any) => {
    object = {
                // $type:
                //   "Chata.Common.Objects.Internal.Participant.S4BPhoneNumberParticipant, Chata.Common.Objects",
                Name: element.Name,
                ContactUri: element.SfBPhoneNumber.ContactUri,
                PhoneNumber:  element.SfBPhoneNumber.PhoneNumber,
              };
              value.push(object);
            });
        this.props.getPhoneNumberTypeAndSendMessage(value,this.props.currentSelectedDropDownValue.UniqueNumber, roomId, text, userEmail, this.state.file)
     
      }
      else
      {
      var userEmail = this.state.currentSelectedDropDownValue !== undefined ? this.state.currentSelectedDropDownValue.email === "Closed" ? this.props.currentMessageRoom.CreatorEmail : this.state.currentSelectedDropDownValue.email : "";
      this.props.getPhoneNumberTypeAndSendMessage(null,this.props.currentSelectedDropDownValue.UniqueNumber, roomId, text, userEmail, this.state.file)
      }
      if (e.currentTarget) e.currentTarget.value = "";
      this.setState({
        fileBlob: "",
        fileName: "",
        file: []
      })
    } 
    }
  };
  handleFailedOutgoingMessage = (err: any) => {
    this.props.setAlertData({
      className: "danger",
      text: "Failed to send the message",
    });
  };
 
  render() {
    let lang=store.getState().reduxReducer.TranslationLanguages;
    let trans=false;
    
    let Listuserlang:any=[];
    if(lang!=undefined)
    {
      trans=true;
    var currentlang=store.getState().reduxReducer.UserTransLang;//(room.TranslationLanguage));this.state.translationSource;//(this.props.currentMessageRoom?.TranslationLanguage!=undefined&&this.props.currentMessageRoom?.TranslationLanguage!=null)?this.props.currentMessageRoom?.TranslationLanguage:
    
    Listuserlang = lang.filter((x: any)  => x.value==currentlang);
     if(Listuserlang.length>0)
     {
      Listuserlang= Listuserlang[0];
     }
    
    }
    const setIndex=(FF:any,x:any)=>{
      const istheImage = (element: any) => element.name ==FF;
      let img=this.state.file.findIndex(istheImage);
this.setState({index:img});
    }
const currentImage:any =this.state.file[this.state.index];
  const nextIndex = (this.state.index + 1) % this.state.file.length;
  const nextImage:any = this.state.file[nextIndex] || currentImage;
  const prevIndex = (this.state.index + this.state.file.length - 1) % this.state.file.length;
  const prevImage:any = this.state.file[prevIndex] || currentImage;

  const handleClick = (index: number) => this.setState({index:index});
  const handleClose = () => this.setState({index:-1});
  const handleMovePrev = () => this.setState({index:prevIndex});
  const handleMoveNext = () =>this.setState({index:nextIndex});
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let messages:any[] = this.props.currentMessageRoom?.Messages?.$values;
    if (this.props.currentMessageRoom?.Messages?.$values && this.state.messages && this.state.messages.length > this.props.currentMessageRoom.Messages.$values.length) {
      messages = this.state.messages;
      
      this.setState({inputText:""});
      setTimeout(() => {
        const elem = document.getElementById("chat-panel");
        elem?.scrollIntoView(false);
      }, 100);
    }
    if (this.props.currentMessageRoom)
    {
      this.props.currentMessageRoom.Participants = this.props.currentMessageRoom.Participants && this.props.currentMessageRoom.Participants.$values ? this.props.currentMessageRoom.Participants.$values : this.props.currentMessageRoom.Participants;}

    return (
      
      <div className="right_Sec" id="right_Sec_main">
        {this.state.shouldShow === true ?
          <ImagePreview imageurl={this.state.imageUrl} closeImage={() => this.setState({ shouldShow: false })} />
          :  null}
        <div className="top_row">
          <div className="top_left">
            <div className="usr_imgcon">
              <div
                className="left_arrow"
                onClick={() => {
                  commonMethod.hideLeftSection();
                  setTimeout(() => { document.getElementById("msg_tab")?.click(); }, 200);
                }}
              >
                <BackIcon id="newMessage-backArrow" className="blk_icon" />
              </div>
              {this.props.currentMessageRoom &&
                this.props.currentMessageRoom.Participants &&
                (this.props.currentMessageRoom.Participants.length > 1 ? (
                  <div className="usr_imgbx grp">
                    {
                      commonMethod.generateFirstLastNameIntials(this.props.currentMessageRoom.Participants[0].Name) === "+" ?
                        <GrayAvtar />
                        :
                        <div className="usr_img2">
                          {commonMethod.generateFirstLastNameIntials(this.props.currentMessageRoom.Participants[0].Name)}
                        </div>
                    }
                    <div className="usr_img2 usr_img22">
                      {commonMethod.generateFirstLastNameIntials(this.props.currentMessageRoom.Participants[1].Name)}
                    </div>
                  </div>
                ) : (
                  <div className="usr_imgbx">
                    {commonMethod.generateFirstLastNameIntials(this.props.currentMessageRoom.Participants[0].Name) === "+" ?
                      <GrayAvtar /> :
                      <div
                        className="usr_img2"
                        style={{ backgroundColor: this.props.currentMessageRoom?.avatarColor }}
                      >
                        {commonMethod.generateFirstLastNameIntials(this.props.currentMessageRoom.Participants[0].Name)}
                      </div>
                    }
                  </div>
                ))}
              <div className="usr_txt">
                <h3>
                  {this.props.currentMessageRoom !== undefined &&
                    this.props.currentMessageRoom !== null &&
                    this.props.currentMessageRoom.length !== 0 &&
                    (this.props.currentMessageRoom.Participants.length < 3
                      ? this.props.currentMessageRoom.Participants.reduce((acc: any, c: any) => acc + ", " + c.Name, "").substr(2)
                      : (this.props.currentMessageRoom.Participants[0] && this.props.currentMessageRoom.Participants[0].Name + ", ") +
                      (this.props.currentMessageRoom.Participants[1] && this.props.currentMessageRoom.Participants[1].Name + ", ") +
                      (this.props.currentMessageRoom.Participants[2] && this.props.currentMessageRoom.Participants[2].Name) + ", and " + (this.props.currentMessageRoom.Participants.length - 3) + " others")}
                </h3>
              </div>
            </div>
          </div>
          <div className="top_right d-flex">
            <div
              className={`more_icon crcl_icon togl_btn ${this.props.infoPanelOpened ? "ylw_bg" : ""}`}
              id="show_info"
              style={{ transition: "background 1s" }}
              onClick={(event: any) => this.props.setInfoPanelOpened(!this.props.infoPanelOpened)}
            >
              <DotsIcon className="blk_icon" />
            </div>
          </div>
        </div>
        <div className="chat_or_info">
          <div
            id="chatDiv"
            className={"chat_sec"}
            style={{ marginRight: this.state.showinfo ? "258px" : "0" }}
          >
            <div className="chat_bx chat_bx_New" id="chat_bx">
              <div className="chat_bxInner" ref={this.chatFeeds}  style ={{overflowY : "hidden",flex:1}} id="DIV_CHAT">
                {/* Chat feed by Niyi */}
                 <Feed />     

              </div>

              <div className="msg_txtbx msg_txtbxDiv" id="msg_txtbxDiv">
           
                <div className="icons_msg_txtbx">
                {trans?
                  <a href="javascript:void(0);" title="" onClick={this.handleTranslate} >
                    <GlobeIcon className="blk_icon" />
                  </a>:<span></span>}
                  {
                    this.props.loggedInUserDefaultCountryCode === 1 ?
                      <label className="browse_file pic-icon-msgbox">
                        <a href="javascript:void(0);" title="">
                          <PicIcon className="blk_icon" />
                        </a>
                        <input type="file" onChange={this.handleChange} id="inputImage" accept="image/*" />
                      </label>
                      :
                      null
                  }
                  {this.state.displayEmojiPicker ? (
                    <div id="emojiPicker" className="new-emoji-picker">
                      <Picker
                        onEmojiClick={(e, data) => { setTimeout(() => this.setState({ inputText: this.state.inputText + data.emoji }), 1000); }}
                        preload={true}
                      ></Picker>
                    </div>
                  ) : null}
                  <a href="javascript:void(0);" title="" id="emojiPickerButton" className={this.state.displayEmojiPicker ? "emoji-focus" : ""}>
                  <EmojiIcon
                        onClick={() => { this.setState({ displayEmojiPicker: !this.state.displayEmojiPicker }); }}
                        className={this.state.displayEmojiPicker ? "primary_icon" : "blk_icon"}
                      />
                  </a>
                </div>
                <div
                  className="srch_input srch_bx"
                  onKeyDown={() => { var heightfind = document.getElementById("msg_txtbxDiv")?.clientHeight; }}
                >
                  {this.state.file ?.length>0 && this.state.file !== null ?
                  this.state.file.map((img:any, idx:any) => (
                    <div className="chatImage" style={{ maxWidth: "50px",display:"inline-block",marginLeft:"20px" }}>
                      <img src={img.url || ''} onClick={ x=>setIndex(img.name,x)} style={{ height: "50px", width: "50px", borderRadius: '5px', border: '1px solid rgb(0 0 0 / 4%)' }} />
                      <progress id="progressBar" value={this.state.progressBar} style={{ width: "110%" }} max="100" ></progress>
                      <CrossIcon onClick={(e:any) =>{ this.setState({
                          file: [], fileName: ""
                        })
                       const imageElement=(document.getElementById('inputImage')as HTMLInputElement);
                       imageElement.value = "";
                      }
                        }></CrossIcon>
                    </div>
                  ))
                    :
                    null
                  }
                  {
                    
                   !!currentImage &&(
                      
                        /* @ts-ignore */
                        <Lightbox
                          mainSrc={currentImage.url}
                          imageTitle={currentImage.caption}
                          mainSrcThumbnail={currentImage.src}
                          nextSrc={nextImage.url}
                          nextSrcThumbnail={nextImage.url}
                          prevSrc={prevImage.url}
                          prevSrcThumbnail={prevImage.url}
                          onCloseRequest={handleClose}
                          onMovePrevRequest={handleMovePrev}
                          onMoveNextRequest={handleMoveNext}
                          discourageDownloads={false}
                          ></Lightbox>
                       
                      
                      // <Lightbox
                      //   mainSrc={this.state.file[0] || ''}
                      //   onCloseRequest={() => this.setState({ isOpen: false })}
                      // />
                    )
                  }
                  <TextareaAutosize
                    rowsMin={1}
                    rowsMax={4}
                    style={{
                      resize: "none",
                      backgroundColor: "var(--onsurfacelowest)",
                    }}
                    disabled={this.props.MFAUSER === true && this.props.ServicePlanId === 6 ? true : false}
                    id="mainInput-SendMessage"
                    value={this.state.inputText}
                    onChange={this.handelTextChange}
                    onKeyDownCapture={this.handelSpecialKey}
                    className="form-control"
                    placeholder="Type a message..."
                    onKeyDown={this.sendMessage}
                  ></TextareaAutosize>
                   <button id="clearChat" style={{display:"none"}} onClick={
                     this.handelTextChange
                  }
           />
                </div>
                {this.props.MFAUSER === true && this.props.ServicePlanId === 6 ?
                ""
                : 
                <a
                  href="javascript:void(0);"
                  title=""
                  className={(this.state.inputText?.length <= 0 && this.state.fileBlob === "" ) ?  "gry_bg" : "ylw_bg"}
                  onClick={() => {
                    if (this.state.inputText?.length > 0 || this.state.fileBlob !== "" ) {
                      
                      this.sendMessage({ key: "Enter" });
                      this.setState({ inputText: "" });
                    }
                  }}
                >
                  <SendIcon
                    className={(this.state.inputText?.length > 0 || this.state.fileBlob !== "") ? "wht_icon" : "grey_icon"}
                  />
                </a>
 }
              </div>
            </div>
          </div>
          <Drawer
            className={this.props.infoPanelOpened ? "Drawer" : "Drawer Drawer-Open"}
            classes={{ paper: "MuiDrawer-paper2" }}
            variant="persistent"
            anchor="right"
            open={this.props.infoPanelOpened}
          >
            {this.props.currentMessageRoom &&
              this.props.currentMessageRoom.Participants ? (
              this.props.currentMessageRoom.Participants.length == 1 ? (
                <div className="info_sec">
                  <div className="cntct_profile text-center">
                    {commonMethod.generateFirstLastNameIntials(this.props.currentMessageRoom?.Participants[0]?.Name) !== "+" ?
                      <div className="profl_img usr_img2" style={{ backgroundColor: this.props.currentMessageRoom?.avatarColor }}>
                        {commonMethod.generateFirstLastNameIntials(this.props.currentMessageRoom?.Participants[0]?.Name)}
                      </div>
                      :
                      <div className="profl_img">
                        <GrayAvtar style={{ width: "80px", height: "80px" }} />
                      </div>
                    }
                    <div className="phone_txt">
                      {this.props.currentMessageRoom?.Participants[0]?.Name}
                    </div>
                  </div>
                  <div className="cntct_info">
                    <h5 className="grey_hedln"> Contact Information </h5>
                    <div className="label1">
                      <label className="grry_txt"> PHONE NUMBER </label>
                      <div className="phone_txt vlu_txt">
                        {" "}{this.props.currentMessageRoom?.Participants[0]?.SfBPhoneNumber?.PhoneNumber}{" "}
                      </div>
                    </div>
                    <div className="label1">
                      <label className="grry_txt"> E-MAIL ADDRESS </label>
                      <div className="phone_txt vlu_txt">
                      </div>
                    </div>
                    <div className="label1">
                      <label className="grry_txt"> Contact type </label>
                    </div>
                    {trans?
                    <div className="label1">
                      <label className="grry_txt">Translation Language</label>
                      <div>
                      <div style={{float:"left",width:"75%",marginTop:"5%"}}>
                      
                      <Select 
                                placeholder="typing language name..."
                                   value={Listuserlang}
                                    onChange={this.handleTranslationChange}
                                    options={lang}
                                  ></Select>
                                  </div>
                                  <div style={{float:"left",width:"5%",marginTop:"5%"}}></div>
                                 <div style={{float:"left",width:"20%",marginTop:"5%"}}><a
                  style={{ marginLeft: "auto" }}
                  href="javascript:;void(0);"
                  className="drag_btn next_btn next_yellow add_grbtn"
                  onClick={(e) => {
                    this.SaveTransltion(e);
                  }}
                >
                  Save
                </a></div> 
                </div>     
                    </div>
           :<div></div>}
                  </div>
                  <div className="cntct_info cnvrt_actn">
                    <h5 className="grey_hedln"> Contact Information </h5>
                    <ul className="optn_list" >
                      <li>
                        {" "}
                        <a href="javascript:void(0);" title="Mute notifications" style={{ color: "var(--onsurfacemedium-text)" }} >
                          {" "}
                          <MuteIcon style={{ fill: "var(--onsurfacemedium-text)" }} />
                          Mute notifications
                        </a>{" "}
                      </li>
                      <li>
                        {" "}
                        <a href="javascript:void(0);" title="Pin to top" style={{ color: "var(--onsurfacemedium-text)" }}>
                          {" "}
                          <PinIcon style={{ fill: "var(--onsurfacemedium-text)" }} />
                          Pin to top
                        </a>{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              ) : (
                <div className="info_sec" style={{ left: "0px" }}>
                  <div className="cntct_profile grp_profile text-center" >
                    <div className="profl_img grp_pro info_grp">
                      {this.props.currentMessageRoom.Participants.length > 1 ? (
                        <>
                          <div className="usr_imgbx grp">
                            <div className="usr_img2" style={{ backgroundColor: this.props.currentMessageRoom.Participants[0].avatarColor }}>
                              {commonMethod.generateFirstLastNameIntials(this.props.currentMessageRoom.Participants[0].Name)}
                            </div>
                            <div className="usr_img2 usr_img22" style={{ backgroundColor: this.props.currentMessageRoom.Participants[1].avatarColor }}>
                              {commonMethod.generateFirstLastNameIntials(this.props.currentMessageRoom.Participants[1].Name)}
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="usr_imgbx">
                          <div className="usr_img2" style={{ backgroundColor: this.props.currentMessageRoom.Participants[0].avatarColor, }}>
                            {commonMethod.generateFirstLastNameIntials(this.props.currentMessageRoom.Participants[0].Name)}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="usr_txt">
                      <h3>
                        {this.props.currentMessageRoom.Participants.length < 3
                          ? this.props.currentMessageRoom.Participants.reduce((acc: any, c: any) => acc + ", " + c.Name, "").substr(2)
                          : (this.props.currentMessageRoom.Participants[0] && this.props.currentMessageRoom.Participants[0].Name + ", ") +
                          (this.props.currentMessageRoom.Participants[1] && this.props.currentMessageRoom.Participants[1].Name + ", ") +
                          (this.props.currentMessageRoom.Participants[2] && this.props.currentMessageRoom.Participants[2].Name) + ", and " + (this.props.currentMessageRoom.Participants.length - 3) + " others"}
                      </h3>
                    </div>
                  </div>
                  <div className="recipnts brdr_tp">
                    <h5 className="grey_hedln" style={{ textAlign: "left" }}>Recipients</h5>
                    <ul className="usr_list ">
                      {this.props.currentMessageRoom.Participants.map((x: any) => {
                        return (
                          <li>
                            <div className="usr_imgbx">
                              <div className="usr_img2" style={{ backgroundColor: x.avatarColor }}>
                                {this.props.currentMessageRoom ? (commonMethod.generateFirstLastNameIntials(this.props.currentMessageRoom.Participants[0].Name)) : (<img src="images/terry.png" />)}
                              </div>
                            </div>
                            <div className="usr_txt ">
                              {/* {console.log("x.Name",x.Name)} */}
                              <h3>
                                <span className="grp_txt" title={x.Name}>
                                  {" "}
                                  {x.Name}{" "}
                                </span>
                              </h3>
                              <div className="txt_div" style={{ margin: "5.5px 0" }}><span className="sub_txt">{x.SfBPhoneNumber.PhoneNumber}{" "}</span></div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              )) : null}
          </Drawer>
        </div>

      </div>
    );
  }
}

const mapStateToProps = (props: any) => {
  let companyVersion=store.getState().reduxReducer.companyVersion;
  return {
    currentSelectedDropDownValue: props.reduxReducer.sharedInboxData?.currentSelected,
    testData: props.reduxReducer.testName,
    tabOpened: props.reduxReducer.tabOpened,
    yakChatGroupData: props.reduxReducer.yakChatGroupData,
    currentMessageRoom: props.reduxReducer.openMessageRoom,
    loggedInUser: props.reduxReducer.loggedInUser,
    infoPanelOpened: props.reduxReducer.infoPanelOpened,
    contactSelected_OpenContact: props.reduxReducer.contactSelected_OpenContact || [],
    currentPageIndexForRoomMessage: props.reduxReducer.currentPageIndexForRoomMessages,
    nextPageIndexForRoomMessages: props.reduxReducer.nextPageIndexForRoomMessages,
    loggedInUserDefaultCountryCode: companyVersion!==2?props.reduxReducer.loggedInUser.DefaultCountryCode:props.reduxReducer.loggedInUser.user.DefaultCountryCode,
    sasToken : props.reduxReducer.sasToken,
    isChatInput:props.reduxReducer.isChatInput,
    MFAUSER:props.reduxReducer.MFAUSER,
    //Added By Maksud
    ServicePlanId: props.reduxReducer.ServicePlanId,
  };
};
async  function translateText (language:any,text:any)  {
    debugger;
    var to=language;
    var from= navigator.language;
    if(to==null)
    {
      return;
    }
    let res:any= await contactservice.TranslateText(text,from,to);
    if(res[0]!==undefined)
    {
    let translatedData=res[0].translations[0].text;
   return translatedData;
    }
    else{
     let errorMsg= JSON.parse(res.message).error.message;
     return "nullerror&£_"+errorMsg;
    }
    
  };
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, null, any>, props: any) => {
  return {
    saveYakChatContact: async (contact: any, savedFrom: any) => {
     let companyVersion=store.getState().reduxReducer.companyVersion;
        if(companyVersion===2)
          {
      dispatch<any>(action.saveYakChatContact_V2(contact, savedFrom));
          }
          else
          {
            dispatch<any>(action.saveYakChatContact(contact, savedFrom));
          }
    },

    getRoomByRoomId: async (userEmail: any, room: any, pageIndex: any) => {
     
      if (room?.Participants !== undefined) {
       let companyVersion=store.getState().reduxReducer.companyVersion;
        if(companyVersion===2)
          {
            userEmail = room.Archived === true ? room.CreatorEmail : userEmail;
            dispatch<any>(action.getPagingMessagesByRoomId_V2(userEmail, room.Id, pageIndex, "ChatPanel"));
          }
          else{
        userEmail = room.Archived === true ? room.CreatorEmail : userEmail;
        dispatch<any>(action.getPagingMessagesByRoomId(userEmail, room.Id, pageIndex, "ChatPanel"));
          }
      }
    },
    setAlertData: (data: any) => {
      dispatch<any>(action.setAlertData(data));
    },
    insertMessageIntoRoom: (messageObject: any, roomId: number) => {
      dispatch<any>(action.insertMessageIntoRoom(messageObject, roomId, ""));
    },
    setInfoPanelOpened: (open: boolean) => {
      dispatch<any>({ type: "setInfoPanelOpened", payload: open });
    },
    SaveTransltion: async (roomId: any, language: any) => {
      debugger;
      dispatch<any>(action.SaveTransltion(roomId, language,true));
      dispatch<any>(action.UserTransLang(language));
    },
    changelang: async ( language: any) => {
      debugger;
      dispatch<any>(action.UserTransLang(language));
    },
    getPhoneNumberTypeAndSendMessage: async (data:any,phoneNumber: any, roomId: any, text: any, userEmail: any, file: any,calledFrom:"chatPanel") => {
      debugger;
      let companyVersion=store.getState().reduxReducer.companyVersion;
      if(companyVersion===2)
      {
        dispatch<any>(action.getPhoneNumberTypeAndSendMessage_V2(data,phoneNumber, roomId, text, userEmail, file,calledFrom));
      }
      else
      {
      dispatch<any>(action.getPhoneNumberTypeAndSendMessage(phoneNumber, roomId, text, userEmail, file,calledFrom));
      }
    },
    setTabOpened: (tab: string) => {
      dispatch<any>(action.setTabOpened(tab));
    },
    setSelectedContacts: (contact: any) => {
      dispatch<any>(action.setContactSelected_OpenContact(contact));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ChatPanel);
